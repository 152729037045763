// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const apiUrl =  'https://api.apps.gocno.io';
// const apiUrl = 'http://localhost:8085';
const notificationUrl = '';
const clientID = '';
const UI_URL = 'cno.app';

export let environment = {
  baseUrl: UI_URL,
  allowedOrganizations: ['startups', 'default'],
  production: true,
  local: false,
  apiBaseURL: apiUrl,
  // socketURL : notificationUrl,
  clientID,
  apimRedirect: "https://apim.cloudoor.com",
  recaptcha: {
    siteKey: '6LfYSjwlAAAAALaafeCPtia-oPIy06WSPH1tDNCQ',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyC9mR0_TEo1XVgYBkddoe0gD_MpoQrEM3E',
    authDomain: 'cno-saas-prod.firebaseapp.com',
    projectId: 'cno-saas-prod',
    storageBucket: 'cno-saas-prod.appspot.com',
    messagingSenderId: '1094995589427',
    appId: '1:1094995589427:web:5438ba85967e5a9b812646',
  },
  githubClientID: "b7c3e3ae101ef2f64075",
  githubClientSecret: "434106e32166a11c4b339ac3eed8cbe7e712a0cf",
  gitlabClientID: "ab5a091fb689096f2553aa29b6b3a67e8707c70ec44d4094f74f22ad702b001d",
  gitlabClientSecret: "gloas-f8d5b69e11ed7e31ba1cc7fa272571e6dac922b113bb6f8dbb3ddf5a51320f84",
  bitbucketClientID: "gLr2R8dKcBDDhWA8Xf",
  bitbucketClientSecret: "mNrUbCnqVBXsVFRfphWELMZWAgUxQ75B",
  gitProviderRedirectURL: "https://" + UI_URL,
  schemas: "https"
  // hotJarId: "3724863"

};
