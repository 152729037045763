import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError} from 'rxjs';
import {catchError, delay, retry} from 'rxjs/operators';
import {Router} from '@angular/router';

import {ShareService} from '../service/share.service';
import {AppAuthGuardService} from '../service/app-auth-guard.service';
import { Session } from '../model/Session';
import {LoginService} from '../../auth/pages/login/services/login.service';
@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private shareService: ShareService,
              private auth: AppAuthGuardService, private router: Router,
              private loginService: LoginService) {}
 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiWithNoHeaders = this.shareService.getWithListApiEndPoint().includes(request.url) || (request.url.includes('users/invitations') && request.url.includes('/accept')) || request.url.includes('users/verification-email') || request.url.includes('users/reset-password') && request.method == 'GET';
    const uiWithNoHeaders = this.shareService.getWhiteListUiEndPoints().includes(request.url);
    if (request.url.includes('/api/v1/')) {
      request = request.clone({
        setHeaders: {
          cloudoor: "v2.1.0"
        }
      });
    }
    if (
      apiWithNoHeaders ||
      uiWithNoHeaders ||
      request.url.includes('/api/v1/organizations/refresh-token') ||
      request.url.includes('/api/v1/organizations/users/SaveOauthTokens') ||
      request.url.includes(this.shareService.googleUrl) ||
      request.url.includes('https://identitytoolkit.googleapis.com/') ||
      request.url.includes("https://bitbucket.org/site/oauth2/access_token") ||
      request.url.includes("https://gitlab.com/api") ||
      request.url.includes("https://api.bitbucket.org/2.0") ||
      request.url.includes("api/v1/organizations/name/") ||
      request.url.includes("/auth/saml/isconfigured")
    ) {
      return next.handle(request);
    }
      let session = this.shareService.getSession();
      if (!session) {
        this.shareService.removeSession();
        this.router.navigate(['/auth/login']);
        return throwError(null);
      }
      if (!this.auth.isTokenValid(session)) {
        this.loginService.refresh(session.refresh_token).subscribe(
          (newSession) => {
            newSession.currentUser = session.currentUser;
            this.shareService.setSession(newSession);
            this.loginService.getCurrentUser().subscribe(
              (currentUser) => {
                newSession.currentUser = currentUser
                this.shareService.updateSession(newSession);
                return this.doRequest(request, next, newSession);
              },
              (error) => {
                this.router.navigate(['/auth/login']);
                return throwError(null);
              }
            )
          },
          (error) => {
            this.router.navigate(['/auth/login']);
            return throwError(null);
          }
        );
      } else {
          return this.doRequest(request, next, session);
      }

  }
  doRequest(request: HttpRequest<any>, next: HttpHandler, session: Session): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${session.access_token}`
    }
  });
  return next.handle(request).pipe(
    retry(1),
    delay(1000),
    catchError((error) => {
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        console.error('Error Event');
      } else {
        if(error.status === 401 && error.error.startsWith("Failed to get userinfo: 401 Unauthorized")){
          this.shareService.removeSession();
          this.router.navigate(['/auth/login/']);
        }else if(error.status === 500){
          retry(1)
        }
      }
    } else {
      console.error('some thing else happened');
    }
    return throwError(error);
  }));
  }
}
