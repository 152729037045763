<div class="modal-content app-content">
  <div class="modal-header align-items-center mb-4">
    <div class="d-flex align-items-center">
      <button type="button" class="btn btn-primary link-btns mr-3" data-bs-toggle="modal" data-bs-target="#right" style="
          border-radius: 50% !important;
          color: #fff !important;
          background: #213a60 !important;
          border-color: #213a60;
        ">
        <i class="bi bi-pencil-fill"></i>
      </button>
      <h5 class="modal-title app-title"> {{ group?.id ? 'Update' : 'Create new' }} group</h5>
    </div>

    <i class="bi bi-x-lg dialog-close-icon" (click)="onCloseDialog()"></i>
  </div>

  <div class="modal-body px-4">
    <form [formGroup]="groupForm">
      <div class="row px-4">
        <div class="col-md-12">
          <div class="form-group">
            <label for="Strategy">Name*</label>
            <input type="text" class="form-control" formControlName="name" />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="Strategy">Description*</label>
            <textarea formControlName="description" class="form-control" rows="2"></textarea>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="Strategy">Choose organizations</label>
            <input type="text" placeholder="Search organization" class="form-control" [formControl]="searchOrg" />
            <div *ngIf="!orgLoader">
              <div *ngIf="organizations.length > 0">
                <div class="form-check" *ngFor="let org of organizations; let i=index">
                  <input class="form-check-input" [checked]="orgIsSelected(org.id)" (click)="onClickOrgCheckbox($event)"
                    type="checkbox" [value]="org.id" [id]="i">
                  <label class="form-check-label" [for]="i">
                    {{ org.name }}
                  </label>
                </div>
              </div>
              <div *ngIf="organizations.length == 0 && organizationsSelected.length == 0">
                No organization found
              </div>
            </div>
            <div *ngIf="orgLoader">
              Organization loading <i class="fa fa-spin fa-spinner"></i>
            </div>
            <div class="wrapper-selected mt-2">
              <span class="el-selected" *ngFor="let org of organizationsSelected">
                {{ org.name }} <i class="fa fa-times" (click)="removeOrgSelected(org.id)"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row py-3 pt-4">
        <div class="col-md-12 text-center">
          <button type="button" class="btn btn-primary update-btn" (click)="onSubmit()"
            [disabled]="loading || (groupForm.invalid)">
            {{ group.id ? 'Update' : 'Create' }}
            <span class="spinner-border text-white" style="font-size: 10px; height: 15px; width: 15px;" role="status"
              *ngIf="loading">
              <span class="sr-only">Loading...</span>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
