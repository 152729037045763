import { Component } from '@angular/core';

@Component({
  selector: 'app-custom-tab',
  templateUrl: './custom-tab.component.html',
  styleUrls: ['./custom-tab.component.scss'],
})
export class CustomTabComponent {
  currentTab = 'organization';

  switchTab(tab: string) {
    this.currentTab = tab;
  }
}
