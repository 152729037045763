import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ShareService } from '../../../../data/service/share.service';
import { CurrentUser, Session } from '../../../../data/model/Session';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router, ActivatedRoute } from '@angular/router';
import * as auth from 'firebase/auth';
import { OrganizationService } from '../../../../data/service/organization.service';
import { environment } from 'src/environments/environment';
import { Auth } from 'firebase/auth';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Type, Position } from 'src/app/utils/const';
import {AppAuthGuardService} from '../../../../data/service/app-auth-guard.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private isLogin: boolean;

  public userEmail: string;

  constructor(
    private httpClient: HttpClient,
    private shareService: ShareService,
    public router: Router,
    private organizationService: OrganizationService,
    private service: AsideExtenderService,
    public afAuth: AngularFireAuth
  ) {}

  getContext() {
    return this.isLogin;
  }

  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }
  GithubAuth() {
    return this.AuthLogin(new auth.GithubAuthProvider());
  }

  async AuthSAML(subDomain: any, codeFromCTL = null) {
    if (subDomain) {
      let samlName = 'saml.' + subDomain;

      return this.afAuth
        .signInWithPopup(new auth.SAMLAuthProvider(samlName))
        .then((res) => {
          this.SetUserSession(res);
          if (codeFromCTL) {
            this.SendTokensToApi(res, codeFromCTL);
          }
        })
        .catch((error) => {
          // this.service.show({
          //   title: 'Auth Failure',
          //   message: 'You have no saml configuration',
          //   type: Type.ERROR,
          //   position: Position.TOP,
          // });
        });
    }
  }

  async TestSAML(subDomain: any) {
    if (subDomain) {
      let samlName = 'saml.' + subDomain;

      return this.afAuth
        .signInWithPopup(new auth.SAMLAuthProvider(samlName))
        .then((res) => {
          this.service.show({
            title: 'Config check',
            message: 'This credentials is valid',
            type: Type.SUCCESS,
            position: Position.TOP,
          });
        })
        .catch((error) => {
          this.service.show({
            title: 'Config check',
            message: 'This credentials is invalid',
            type: Type.ERROR,
            position: Position.TOP,
          });
        });
    }
  }

  // Sign in with email/password
  SignIn(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }
  CheckTheEmailVerificationLink(code: string, key: string) {
    this.afAuth
      .checkActionCode(code)
      .then((resp) => {
        let url = `https://identitytoolkit.googleapis.com/v1/accounts:update?key=${key}`;
        this.httpClient.post(url, { oobCode: code }).subscribe(
          (response) => {
            this.service.show({
              title: 'Verification email',
              message: 'Your email has been verified',
              type: Type.SUCCESS,
              position: Position.TOP,
            });
          },
          (error) => {
            this.service.show({
              title: 'Verification email',
              message: 'One error has occurred during verification',
              type: Type.ERROR,
              position: Position.TOP,
            });
          }
        );
      })
      .catch((error) => {
        this.service.show({
          title: 'Verification email',
          message: error.message,
          type: Type.ERROR,
          position: Position.TOP,
        });
      });
  }

  // Sign up with email/password
  SignUp(email: string, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['/auth/verify-email-address']);
      });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  AuthLogin(provider: any) {
    return this.afAuth.signInWithPopup(provider);
  }

  getCurrentUser(): Observable<CurrentUser> {
    return this.httpClient.get<CurrentUser>(
      this.shareService.apiBaseURL + '/api/v1/users/infos'
    );
  }

  sendVerificationEmailFromApi(email: string): Observable<any> {
    this.userEmail = email;
    return this.httpClient.get<any>(
      this.shareService.apiBaseURL +
        '/api/v1/organizations/users/verification-email?email=' +
        email
    );
  }

  sendResetPassordFromApi(email): Observable<any> {
    return this.httpClient.get<any>(
      this.shareService.apiBaseURL +
        '/api/v1/organizations/users/reset-password?email=' +
        email
    );
  }

  refresh(refreshToken: string): Observable<Session> {
    let data = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    };
    return this.httpClient.post<Session>(
      this.shareService.googleUrl +
        '/v1/token?key=' +
        environment.firebaseConfig.apiKey,
      data
    );
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut();
  }

  SetUserSession(authData: any) {
    authData.user.getIdTokenResult().then((res) => {
      let username = authData.additionalUserInfo.username
        ? authData.additionalUserInfo.username
        : authData.user.localId;
      let currentUser: CurrentUser = {
        email: authData.user.email,
        email_verified: authData.user.emailVerified,
        displayName: authData.user.displayName,
        username: username,
        roles: [],
      };
      authData.user.getIdToken().then(async (idtoken) => {
        let session: Session = {
          expires_in: 3000,
          access_token: idtoken,
          refresh_token: authData.user.refreshToken.toString(),
          scope: '',
          session_state: '', //
          token_type: 'Bearer',
          createdAt: Date.now(),
          id_token: idtoken,
          currentUser: currentUser,
        };
        this.shareService.setSession(session);
        this.getCurrentUser().subscribe(
          async (data) => {
            session.currentUser = data;
            this.shareService.updateSession(session);
            this.shareService.email = session.currentUser.email;
            await this.organizationService.fetchOrganizations(session);
            if (this.shareService.organizations.length > 0) {
              if (!this.shareService?.organizationSelected) {
                // this.shareService.logout();
                return;
              }
              this.router.navigate(['/dashboard']);
            } else {
              this.service.show({
                title: 'Login User',
                message: "You're not tied to any organization",
                type: Type.ERROR,
                position: Position.TOP,
              });
              this.shareService.logout();
            }
          },
          (error) => {
            this.service.show({
              title: 'Login User',
              message: error.error,
              type: Type.ERROR,
              position: Position.TOP,
            });
          }
        );
      });
    });
  }

  // Send tokens to api for CTL
  async SendTokensToApi(authData: any, code: string): Promise<Observable<any>> {
    let accessToken;
    await authData.user.getIdToken().then((idtoken) => {
      accessToken = idtoken;
    });
    let refreshToken = authData.user.refreshToken.toString();

    let body: any = {
      accessToken,
      refreshToken,
    };
    let url =
      this.shareService.apiBaseURL +
      '/api/v1/organizations/users/SaveOauthTokens?code=' +
      code;
    return this.httpClient.post<any>(url, body);
  }

   SimpleSendTokensToApi(): Observable<any> {
    let authData = this.shareService.getSession();
    if(!authData){
      this.router.navigate(['/auth/login/']);
    }
    let body: any = {
       accessToken: authData.access_token,
       refreshToken: authData.refresh_token
    };
    return this.httpClient.post<any>(this.shareService.apiBaseURL + '/api/v1/organizations/users/SaveOauthTokens', body);
  }
}
