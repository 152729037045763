import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Session } from '../model/Session';
import { Organization } from '../model/organization';
import { environment } from 'src/environments/environment';
import { AsideExtenderService } from './aside-extender.service';
import { Position, Type } from 'src/app/utils/const';
import { AngularFireAuth } from '@angular/fire/compat/auth';


@Injectable({
  providedIn: 'root'
})
export class ShareService {
  private _organizationSelected: Organization;
  private _organizations: Organization[] = [];
  private _apiBaseURL: string = "https://cno-api.dev.gocno.io";
  private _clientID: string;
  private _socketURL: string;
  private _errorMessage;
  private _googleUrl: string = 'https://securetoken.googleapis.com';
  private _isFree: boolean;

  email: string;
  constructor(private service: AsideExtenderService, private afAuth: AngularFireAuth, public router: Router
    ) {}

  setOrganizationSelected(organization: Organization, session: Session) {
    this.organizationSelected = organization;
    this.email = session.currentUser.email;
    if (environment?.local && this.organizationSelected) {
      localStorage.setItem("organization", this.organizationSelected.name)
    }
    if (
      this.organizationSelected && this.organizationSelected?.subscriptionType.includes('free')
    ) {
      this.isFree = true
    }
    else {
      this.isFree = false
    }
  }

  getWithListApiEndPoint(): string[] {
    const email = this.email;
    return [
      this.apiBaseURL + "/api/v1/organizations/login",
      this.apiBaseURL + "/api/v1/organizations/signup",
      this.apiBaseURL + "/api/v1/organizations/user/email/" + email + "/resetpassword",
      this.apiBaseURL + "/api/v1/organizations/" + this.organizationSelected?.id + "/user/changepassword",
      this.apiBaseURL + "/api/v1/organizations",
      "/users/verification-email",
      "/users/reset-password",
      this.apiBaseURL + "/api/v1/organizations/name/" + this.organizationSelected?.name,
      this.apiBaseURL + "/api/v1/organizations/" + this.organizationSelected?.id + "/auth/saml/isconfigured",
      this.apiBaseURL + "/api/v1/organizations/users/SaveOauthTokens",
    ];
  }

  getWhiteListUiEndPoints(): string[] {
    return [
      window.location.origin + "/api-url",
      window.location.origin + "/notification-url",
      window.location.origin + "/oidc-client-id"
    ];
  }

  public get apiBaseURL(): string {
    return this._apiBaseURL;
  }

  public set apiBaseURL(apiUrl: string) {
    this._apiBaseURL = apiUrl;
  }

  public get clientID(): string {
    return this._clientID;
  }

  public set clientID(clientID: string) {
    this._clientID = clientID;
  }

  public get googleUrl(): string {
    return this._googleUrl;
  }

  public set googleUrl(googleUrl: string) {
    this._googleUrl = googleUrl;
  }


  public get socketURL(): string {
    return this._socketURL;
  }

  public set socketURL(socketUrl: string) {
    this._socketURL = socketUrl;
  }

  public get organizationSelected(): Organization {
    return this._organizationSelected;
  }

  public set organizationSelected(organization: Organization) {
    this._organizationSelected = organization;
  }

  public get organizations(): Organization[] {
    return this._organizations;
  }

  public set organizations(organizations: Organization[]) {
    let organizationsList = organizations?.slice().sort((a, b) => a.name.localeCompare(b.name));
    this._organizations = organizationsList;
  }

  public get errorMessage(): string {
    return this._errorMessage;
  }

  public set errorMessage(errorMessage: string) {
    this._errorMessage = errorMessage;
  }

  get isFree(): boolean {
    return this._isFree;
  }

  set isFree(value: boolean) {
    this._isFree = value;
  }

  logout() {
    this.afAuth.signOut()
      .then((res) => {
        this.removeSession();
        this.resetAll()
        this.router.navigate(['/auth/see-you-soon']);
        localStorage.clear()
      })
      .catch((error) => {
        console.log(error);
      });
    return true;
  }

  resetAll() {
    this.organizations = undefined;
    this.organizationSelected = undefined;
    this.email = undefined;
  }

  removeSession() {
    localStorage.removeItem('session');
  }
  setSession(session: Session) {
    session.createdAt = Date.now();
    localStorage.setItem('session', JSON.stringify(session));
  }
  updateSession(session: Session) {
    localStorage.setItem('session', JSON.stringify(session));
  }
  getSession(): Session {
    return  JSON.parse(localStorage.getItem('session')) as Session;
  }
}

