import { Component, HostListener, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Position, Type } from 'src/app/utils/const';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';
import { ClusterService } from '../../../../clusters/services/cluster.service';
import { Cluster } from 'src/app/data/model/cluster';
import { GetRecordsModel } from 'src/app/data/model/GetRecordsModel';
import { Router } from '@angular/router';
import { DeploymentService } from '../../../services/deployment.service';
import { Deployment } from 'src/app/data/model/deployment';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { equalsTo } from 'src/app/modules/app/registry/registry.component';
import { Project } from 'src/app/data/model/project';
import { Environment } from 'src/app/data/model/environment';
import { ProjectService } from 'src/app/modules/onboard/services/project.service';

@Component({
  selector: 'app-deployment',
  templateUrl: './deployment.component.html',
  styleUrls: ['./deployment.component.scss'],
})
export class DeploymentComponent {
  formSubmited = false;
  deployments: Deployment[] = [];
  currentModal: string = '';
  deploymentLoaded: boolean = false;
  alerting: boolean = false;
  disabledBtn = false;
  modalRef: BsModalRef;
  currentDeployment: Deployment;
  clusters: Cluster[] = [];
  currentCluster: Cluster;
  total = 0;
  page = 1;
  limit = 15;
  search = '';

  deleteDeployForm: UntypedFormGroup;
  dropdownStates: boolean[] = [];
  ignoreClick = false;

  projects: Project[] = [];
  loaderProject: boolean = false;
  currentProject: Project;
  currentFilterProject: string = '';
  projectIdFormControl: FormControl;

  environments: Environment[] = [];
  loaderEnvironment: boolean = false;
  currentEnvironment: Environment;
  currentFilterEnvironment: string = '';
  environmentIdFormControl: FormControl;

  deploymentTypeFailed = ['ReleaseFailed'];

  filterTmpName = 'filter-deploy';
  filter: any = {};

  sortByField = {
    'metadata.name': true,
    lastUpdatedDate: true,
    action: true,
  };

  domainSelected = "";

  constructor(
    private _location: Location,
    private modalService: BsModalService,
    private service: AsideExtenderService,
    private deploymentService: DeploymentService,
    private projectService: ProjectService,
    private clusterService: ClusterService,
    private router: Router,
    public auth: AppAuthGuardService
  ) {}

  ngOnInit(): void {
    this.filter = localStorage.getItem(this.filterTmpName);
    if (this.filter) {
      this.filter = JSON.parse(this.filter);
      this.currentProject = new Project();
      this.currentEnvironment = new Environment();
    }
    this.getAllDeployments(
      this.page,
      this.limit,
      this.filter?.search,
      this.filter?.projectId,
      this.filter?.environmentId
    );

    this.dropdownStates = [];
    this.projectIdFormControl = new FormControl('All');
    this.environmentIdFormControl = new FormControl('All');
    this.getProjects();
  }

  onSelectDomain(host: string){
    if (host) {
      this.domainSelected = host;
    }
  }

  OnSortByField(field: string, asc: boolean) {
    this.sortByField[field] = !asc;
    if (field == 'action') {
      this.sortByStatus(this.deployments, !asc);
    } else {
      this.sortDeployments(this.deployments, !asc, field);
    }
  }

  isOld(deployment: Deployment){
    if (Array.isArray(deployment?.spec?.ingress)) {
      return false;
    }
    return true;
  }

  getValue(obj: any, path: any) {
    return path
      .split('.')
      .reduce((acc: any, part: any) => acc && acc[part], obj);
  }

  sortDeployments(data: any, asc: boolean, field: string) {
    return data.sort((a: any, b: any) => {
      const valA = field ? this.getValue(a, field) : a;
      const valB = field ? this.getValue(b, field) : b;

      if (valA < valB) return asc ? -1 : 1;
      if (valA > valB) return asc ? 1 : -1;
      return 0;
    });
  }

  hasConditionType(obj: any, type: string) {
    const status = obj?.status?.conditions || [];
    return status.some((item: any) => item.type === type);
  }

  sortByStatus(data: any, asc: boolean) {
    return data.sort((a: any, b: any) => {
      const hasReleaseFailedA = this.hasConditionType(a, 'ReleaseFailed');
      const hasReleaseFailedB = this.hasConditionType(b, 'ReleaseFailed');

      const hasDeployedConditionA = this.hasConditionType(a, "Deployed") && a.desiredReplicas !== a.liveReadyReplicas;
      const hasDeployedConditionB = this.hasConditionType(b, "Deployed") && b.desiredReplicas !== b.liveReadyReplicas;

      if (hasReleaseFailedA && !hasReleaseFailedB) return asc ? -1 : 1;
      if (!hasReleaseFailedA && hasReleaseFailedB) return asc ? 1 : -1;

      if (hasDeployedConditionA && !hasDeployedConditionB) return asc ? -1 : 1;
      if (!hasDeployedConditionA && hasDeployedConditionB) return asc ? 1 : -1;
      return 0;
    });
  }

  onChangeProject(value: any) {
    this.environmentIdFormControl.setValue('');
    if (value) {
      this.currentProject = this.projects.find((p) => p.id == value);
      this.environments = [];
      let env = new Environment();
      env.name = 'All';
      this.environments.push(env);
      this.environmentIdFormControl = new FormControl('All');
      if (this.currentProject?.environments.length > 0) {
        this.environments.push(...this.currentProject?.environments);
      }
      this.getAllDeployments(
        this.page,
        this.limit,
        this.search,
        this.currentProject?.id
      );
    } else {
      this.filter.projectId = '';
      this.filter.environmentId = '';
      localStorage.setItem(this.filterTmpName, JSON.stringify(this.filter));
      this.getAllDeployments(this.page, this.limit, this.search);
    }
  }

  getProjects(search: string = '') {
    return new Promise((resolve, reject) => {
      this.loaderEnvironment = true;
      this.projectIdFormControl.disable();
      this.environmentIdFormControl.disable();
      this.projectService.getProjectsByOrganisation(search).subscribe(
        (response) => {
          this.projects = [];
          let project = new Project();
          project.name = 'All';
          this.projects.push(project);
          this.projects.push(...response.records);
          this.projectIdFormControl.enable();
          this.loaderEnvironment = false;
          this.environmentIdFormControl.enable();
          if (this.filter?.projectId) {
            this.projectIdFormControl.setValue(this.filter?.projectId);
          }
          if (this.filter?.environmentId) {
            setTimeout(() => {
              this.environmentIdFormControl.setValue(
                this.filter?.environmentId
              );
            }, 500);
          }
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async onChangeEnvironment(value: any) {
    if (value) {
      const environments = this.currentProject.environments;
      if (environments.length > 0) {
        this.currentEnvironment = environments.find((e) => e.id == value);
        if (this.currentEnvironment) {
          this.getAllDeployments(
            this.page,
            this.limit,
            this.search,
            this.currentProject?.id,
            this.currentEnvironment?.id
          );
        }
      }
    } else {
      this.getAllDeployments(
        this.page,
        this.limit,
        this.search,
        this.currentProject?.id
      );
    }
  }

  onCloseBanner(elId) {
    setTimeout(() => {
      document.getElementById(elId).innerHTML = '';
      document.getElementById(elId).style.width = '0';
      document.getElementById(elId).style.padding = '0';
      document.getElementById(elId).style.background = 'transparent';
    }, 100);
  }

  toggleDropdown(index: number) {
    this.dropdownStates = this.deployments.map((_, i) =>
      i === index ? !this.dropdownStates[i] : false
    );
    this.ignoreClick = true;
    setTimeout(() => {
      this.ignoreClick = false;
    }, 0);
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const containsTrue = this.dropdownStates.some((value) => value === true);
    if (containsTrue && !this.ignoreClick) {
      this.dropdownStates = [];
    }
  }
  onCreate() {
    this.router.navigate(['/deploy/create']);
  }

  onEdit(deployment: Deployment) {
    this.router.navigate(['/deploy/edit/' + deployment.id], {
      queryParams: {
        projectId: deployment.projectId,
        environmentId: deployment.environmentId,
      },
    });
  }

  onGotoDeliver(deployment: Deployment) {
    if (deployment.workloadId) {
      this.router.navigate(['/deliver/' + deployment.workloadId], {
        queryParams: {
          proj: deployment.projectId,
          env: deployment.environmentId,
        },
      });
    }
  }

  onDuplicate(deployment: Deployment) {
    this.router.navigate(['/deploy/create'], {
      queryParams: {
        id: deployment.id,
        projectId: deployment.projectId,
        environmentId: deployment.environmentId,
        duplicate: true,
      },
    });
  }

  getClusters(): void {
    this.clusterService.getAll().subscribe((data) => {
      this.deploymentLoaded = true;
      this.clusters = data.records;
    });
  }

  deploymentIsFailed(deployment: Deployment, checkReplicas = true) {
    const conditions = deployment?.status?.conditions;

    let failed = false;

    if (conditions) {
      const conditionFailed = conditions.find((cond: any) =>
        this.deploymentTypeFailed.includes(cond.type)
      );

      if (conditionFailed) {
        failed = true;
      }

      if (deployment?.liveReadyReplicas != deployment?.desiredReplicas) {
        failed = true;
      }

      if (!checkReplicas && conditionFailed) {
        failed = false;
      }
    }

    return failed;
  }

  elipsize(str: string, size: number) {
    if (str) {
      this.domainSelected = str;
      if (str.length > size) {
        return str.substring(0, size) + '...';
      }
      return str;
    }
    return '';
  }
  getClusterName(clusterId) {
    const cluster = this.clusters.find((c) => c.id == clusterId);
    if (cluster) {
      return cluster.name;
    }
    return 'Not found';
  }

  async onCopyApiGatewayUrl(clusterId: any) {
    if (this.clusters.length > 0) {
      const findCluster = this.clusters.find((c) => c?.id == clusterId);
      if (findCluster) {
        try {
          await navigator.clipboard.writeText(findCluster?.apiGatewayAddresses);
          this.service.show({
            title: 'Copy success',
            message: 'Link has been copied',
            type: Type.SUCCESS,
            position: Position.TOP,
          });
        } catch (err) {
          this.service.show({
            title: 'Copy error',
            message: err,
            type: Type.ERROR,
            position: Position.TOP,
          });
        }
      }
    }
  }

  getAllDeployments(
    page: any,
    limit: any,
    search: any,
    projectId?: string,
    environmentId?: string
  ): void {
    this.deploymentLoaded = false;
    this.deploymentService
      .getAll(page, limit, search, projectId, environmentId)
      .subscribe(
        (response: GetRecordsModel<Deployment>) => {
          this.deployments = response.records;
          this.page = response.page;
          this.limit = response.limit;
          this.total = response.totalRecord;
          this.sortByField['lastUpdatedDate'] = false;
          this.sortDeployments(this.deployments, false, 'lastUpdatedDate');
          localStorage.setItem(
            this.filterTmpName,
            JSON.stringify({
              projectId: this.currentProject?.id,
              environmentId: this.currentEnvironment?.id,
              search: this.search,
            })
          );

          if (response.records.length == 0) {
            this.page = 0;
          }

          this.getClusters();
        },
        (err) => {
          this.service.show({
            type: Type.ERROR,
            message: err.error,
            title: 'Deployment',
            position: Position.TOP,
          });
          this.deploymentLoaded = true;
        }
      );
  }

  getPage(page: number) {
    this.getAllDeployments(page, this.limit, this.search);
    this.dropdownStates = [];
  }

  onUpSearch(search: any): void {
    if (search.length > 0) {
      this.search = search;
      this.page = 1;
    } else {
      this.page = 1;
      this.search = '';
    }

    if (this.currentProject?.id) {
      this.getAllDeployments(
        this.page,
        this.limit,
        this.search,
        this.currentProject.id
      );
    } else if (this.currentEnvironment?.id) {
      this.getAllDeployments(
        this.page,
        this.limit,
        this.search,
        this.currentProject.id,
        this.currentEnvironment.id
      );
    } else {
      this.getAllDeployments(this.page, this.limit, this.search);
    }
  }

  onOpenAction(id, state) {
    // state = state == 'show' ? 'show' : 'hide';
    // if (state=='show') {
    //   $(`.${id}`).addClass(state);
    // }else{
    //   $(`.${id}`).removeClass(state);
    // }
  }

  formatDateToHuman(dateStr) {
    const date = moment(dateStr);
    return date.fromNow();
  }

  goBack(): void {
    this._location.back();
  }

  onUpDeploymentName(name: any): void {
    if (this.currentDeployment?.metadata?.name === name) {
      this.disabledBtn = false;
    } else {
      this.disabledBtn = true;
    }
  }

  onOpenDeleteModal(template: TemplateRef<any>, deployment: Deployment) {
    this.currentDeployment = deployment;
    this.disabledBtn = true;
    this.deleteDeployForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required,
        equalsTo(this.currentDeployment.metadata?.name),
      ]),
      forced: new UntypedFormControl(false),
    });

    const config: ModalOptions = {
      class: 'modal-md modal-dialog-centered',
      backdrop: 'static',
    };
    this.modalRef = this.modalService.show(template, config);
  }

  onOpenInfoStatusModal(template: TemplateRef<any>, deployment: Deployment) {
    this.currentDeployment = deployment;

    const config: ModalOptions = {
      class: 'modal-md modal-dialog-centered',
    };

    this.modalRef = this.modalService.show(template, config);
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  onSubmitDeleteDeployment(): void {
    if (this.deleteDeployForm.valid) {
      this.deploymentService
        .deleteDeployment(
          this.currentDeployment?.projectId,
          this.currentDeployment?.environmentId,
          this.currentDeployment?.id,
          this.deleteDeployForm.get('forced').value
        )
        .subscribe(
          () => {
            this.getAllDeployments(this.page, this.limit, this.search);
            this.service.show({
              title: 'Delete Deployment',
              message: 'Deployment deleted successfully',
              type: Type.SUCCESS,
              position: Position.TOP,
            });
            this.onCloseDialog();
          },
          (error) => {
            this.service.show({
              title: 'Delete Deployment',
              message: error?.error,
              type: Type.ERROR,
              position: Position.TOP,
            });
            this.onCloseDialog();
          }
        );
    }
  }
}
