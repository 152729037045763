import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Organization } from 'src/app/data/model/organization';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { OrganizationService } from 'src/app/data/service/organization.service';
import {
  Position,
  Type,
  CHAGEBEE_PLANS,
  CHAGEBEE_STATUS_ACCESS,
  CHAGEBEE_STATUS_REJECT,
} from 'src/app/utils/const';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss'],
})
export class FormModalComponent {
  public event: EventEmitter<any> = new EventEmitter();

  organization: Organization;
  organizationForm: FormGroup;
  loading = false;
  chargebeePlans = CHAGEBEE_PLANS;
  chargebeeStatus = [...CHAGEBEE_STATUS_ACCESS, ...CHAGEBEE_STATUS_REJECT];
  subscriptionTypes = [];

  constructor(
    private modalRef: BsModalRef,
    private organizationService: OrganizationService,
    private service: AsideExtenderService
  ) {}

  ngOnInit(): void {
    this.getChargeBeePlans();
    this.initForm();
  }

  getChargeBeePlans() {
    this.subscriptionTypes = Object.values(this.chargebeePlans);
  }

  initForm() {
    this.organizationForm = new FormGroup({
      name: new FormControl(this.organization?.name, [Validators.required]),
      chargebeeCustomerId: new FormControl(
        this.organization?.chargebeeCustomerId
      ),
      chargebeeSubscriptionId: new FormControl(
        this.organization?.chargebeeSubscriptionId
      ),
      chargebeeSubscriptionStatus: new FormControl(
        this.organization?.chargebeeSubscriptionStatus
      ),
      subscriptionType: new FormControl(this.organization?.subscriptionType),
      numberOfUser: new FormControl(
        this.organization?.numberOfUser ? this.organization?.numberOfUser : 1
      ),
      configCompleted: new FormControl(this.organization?.configCompleted),
      isContacted: new FormControl(this.organization?.isContacted),
      url: new FormControl(this.organization?.url),
    });
  }

  onSubmit() {
    const formValue = this.organizationForm.value;
    const organization = this.organization.id
      ? this.organization
      : new Organization();

    organization.name = formValue.name;
    organization.chargebeeCustomerId = formValue.chargebeeCustomerId;
    organization.chargebeeSubscriptionId = formValue.chargebeeSubscriptionId;
    organization.chargebeeSubscriptionStatus =
      formValue.chargebeeSubscriptionStatus;
    organization.subscriptionType = formValue.subscriptionType;
    organization.numberOfUser = formValue.numberOfUser;
    organization.configCompleted = formValue.configCompleted;
    organization.isContacted = formValue.isContacted;
    organization.url = formValue.url;

    this.loading = true;

    // For update
    if (this.organization?.id) {
      this.organizationService
        .update(this.organization?.id, organization)
        .subscribe(
          (data) => {
            this.loading = false;
            this.event.emit({ appId: data, res: 200 });
            this.organizationForm.reset();
            this.onCloseDialog();
            this.service.show({
              title: 'Organization',
              position: Position.TOP,
              type: Type.SUCCESS,
              message: 'updated successfully',
            });
          },
          (error) => {
            this.loading = false;
            this.service.show({
              title: 'Error Organization',
              position: Position.TOP,
              type: Type.ERROR,
              message: error.error,
            });
          }
        );
    }
    // For create
    else {
      this.organizationService.create(organization).subscribe(
        async (data) => {
          this.loading = false;
          this.event.emit({ appId: data, res: 200 });
          this.organizationForm.reset();
          this.onCloseDialog();
          this.service.show({
            title: 'Organization',
            position: Position.TOP,
            type: Type.SUCCESS,
            message: 'created successfully',
          });
        },
        (error) => {
          this.loading = false;
          this.service.show({
            title: 'Error Organization',
            position: Position.TOP,
            type: Type.ERROR,
            message: error.error,
          });
        }
      );
    }
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }
}
