<section class="main px-3">
  <div class="g-0">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="tableheading row">
        <div class="col-md-5 ">
          <div class="row">
            <div class="col">
              <span style="font-size: 14px;">
                List of groups ({{ total.toString().padStart(2, "0") }})
              </span>
            </div>
          </div>
        </div>
        <div class="box_right col-md-7">
          <div class="row p-0">
            <div class="col-md-12 text-right p-0 m-0">
              <a (click)="onOpenFormModalGroup()" class="btn-right blue mt-2">
                Create new group
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Table -->
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-4">
        <div class="table_wrapper">
          <div class="content_table">
            <div class="table-responsive">
              <table class="table member-table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Organizations</th>
                    <th scope="col" class="role">Action</th>
                  </tr>
                </thead>

                <tbody *ngIf="total > 0 && !groupLoaded">
                  <tr
                    *ngFor="let group of groups">
                    <td>{{ group?.name }}</td>
                    <td>
                      <div *ngIf="group?.organizations?.length > 0">
                        <span *ngFor="let org of group?.organizations; let i=index">
                          {{ org.name }},
                        </span>
                      </div>
                    </td>
                    <td class="">
                      <div class="cancle">
                        <a (click)="onOpenFormModalGroup(group)">
                          <i class="bi-pencil-square edit mr-3"></i>
                        </a>
                        <i class="bi bi-x-lg" (click)="onOpenDeleteModal(template, group)"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <spinner *ngIf="groupLoaded"></spinner>
              <p *ngIf="groupLoaded" class="no-app-found">Loading groups</p>
              <p *ngIf="!groupLoaded && total == 0" class="no-app-found">No groups found.</p>
            </div>
            <div class="pcontrols" *ngIf="total > 0">
              <pagination (pageChanged)="pageChanged($event)" [(ngModel)]="page" [boundaryLinks]="true" [totalItems]="total" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
                lastText="&raquo;">
              </pagination>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<ng-template #template>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="m-0 p-0 popup-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="onCloseDialog()">
        <i class="bi bi-x-lg"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="delete-popup">
        <div *ngIf="!cannotDelete">
          <h3 class="heading">Are you sure ?</h3>
          <div class="delete-text">
            Delete group <br />« <strong>{{ groupSelected.name }}</strong> »
          </div>
          <input (keyup)="onUpGroupName($event.target.value)" type="text" class="form-control"
            placeholder="Type name" />
          <br>
          <small>
            All group-related resources will be permanently deleted.
          </small>
        </div>
        <div class="attachment-link mt-3" [class]="!cannotDelete ? 'd-flex justify-content-between': 'text-center'">
          <button class="btn-delete" type="button" [disabled]="disabledBtn"
            (click)="onSubmitDeleteGroup()">
            Delete
          </button>
          <button class="btn-cancel" (click)="onCloseDialog()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #infoTemplate>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="m-0 p-0 popup-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="onCloseDialog()">
        <i class="bi bi-x-lg"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="delete-popup">
        <h3 class="heading">Info delete</h3>
        <div class="delete-text">
          Before deleting the group, check that the elements in Code, Environment and Cluster have been deleted.
        </div>
        <button class="btn-cancel" (click)="onCloseDialog()">Close</button>
      </div>
    </div>
  </div>
</ng-template>
