import {  HttpHeaders } from '@angular/common/http';
import {AfterViewInit, Component, Injectable, OnInit} from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from './modules/app/app.service';
import { Router } from '@angular/router';
import {Location} from "@angular/common"
import { ShareService } from './data/service/share.service';
import {AppAuthGuardService} from './data/service/app-auth-guard.service';

@Injectable({
  providedIn: 'root',
})

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'cno-ui';
  currentOrg: string
  constructor(private appService: AppService,
    private router: Router,
    private location: Location,
    private auth: AppAuthGuardService,
    public shareService: ShareService,

    ) { }


  ngOnInit(): void {
    const url = new URL(window.location.href);
    let gitProviderName = url.searchParams.get("provider");
    this.currentOrg = url.searchParams.get("currentOrg");
    const code = url.searchParams.get("code");
    const first = url.searchParams.get("first")
    if((gitProviderName == null || this.currentOrg== null || code == null) && this.location.path() == ""){
      this.router.navigate(['/dashboard']);
    }else if(first == 'true' || this.currentOrg == 'default' || environment.local) {
      if (code && gitProviderName === "github") { this.exchangeGithubCodeForToken(code); }
      if (code && gitProviderName === "gitlab") { this.exchangeGitlabCodeForToken(code); }
      if (code && gitProviderName === "bitbucket") { this.exchangeBitbucketCodeForToken(code); }
    }
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'x-www-form-urlencoded',
      "Accept": "x-www-form-urlencoded"
    }),
  };

  navigateOrg(gitProviderName: string, token: string) {
    this.router.navigate(["/apps"], {queryParams: {"provider": gitProviderName, "token": token}})
  }

  loadPage():boolean {
    if (this.shareService?.organizationSelected) {
      return true
    }
    if (!this.shareService?.organizationSelected && this.auth.isUIAnonymous()) {
      return true
    }

    return false;
  }

  exchangeGithubCodeForToken(code: string) {
    const requestData = {
      client_id: environment.githubClientID,
      client_secret: environment.githubClientSecret,
      code: code,
      grant_type: 'authorization_code',
    };
    this.appService.getGithubUserToken(requestData).subscribe((response: any) => {
      if(response?.access_token){
        this.navigateOrg("github", response?.access_token)
      }
    }, (error) => {
      console.error('Erreur :', error);
    });
  }

  exchangeGitlabCodeForToken(code: string) {
    const requestData = {
      client_id: environment.gitlabClientID,
      client_secret: environment.gitlabClientSecret,
      code: code,
      grant_type: 'authorization_code',
    };
    this.appService.getGitlabUserToken(requestData, this.currentOrg).subscribe((response: any) => {
      if(response?.access_token){
        this.navigateOrg("gitlab", response?.access_token)
      }
    }, (error) => {
      console.error('Erreur :', error);
    });
  }

  exchangeBitbucketCodeForToken(code: string) {
    const requestData = {
      client_id: environment.bitbucketClientID,
      client_secret: environment.bitbucketClientSecret,
      code: code,
      grant_type: 'authorization_code',
    };
    this.appService.getBitbucketUserToken(requestData, this.currentOrg).subscribe((response: any) => {

      if(response?.access_token){
        this.navigateOrg("bitbucket", response?.access_token)
      }
    }, error => console.log(error)
    );
  }
  ngAfterViewInit() {

  }
}
