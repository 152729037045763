import { Component, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Group } from 'src/app/data/model/group';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Position, Type } from 'src/app/utils/const';
import { GroupService } from '../../service/group.service';
import { OrganizationService } from 'src/app/data/service/organization.service';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss'],
})
export class FormModalComponent {
  public event: EventEmitter<any> = new EventEmitter();

  organizations = [];
  organizationsSelected = [];
  group: Group;
  groupForm: FormGroup;
  loading = false;
  searchOrg: FormControl;
  limit = 5;
  orgLoader = false;

  constructor(
    private modalRef: BsModalRef,
    private groupService: GroupService,
    private organizationService: OrganizationService,
    private service: AsideExtenderService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.searchOrg = new FormControl('');
    this.searchOrg.valueChanges.subscribe((value: any) => {
      this.onUpSearch(value);
    });
    this.organizationsSelected = [];
    if (this.group?.organizations) {
      this.group?.organizations.forEach((org) => {
        this.organizationsSelected.push(org);
      });
    }

    this.groupForm = new FormGroup({
      name: new FormControl(this.group?.name, [Validators.required]),
      description: new FormControl(this.group?.description, [Validators.required]),
    });
  }

  onClickOrgCheckbox(event: any) {
    const orgId = event.target.value;
    if (event.target.checked) {
      const org = this.organizations.find((org) => org.id == orgId);
      if (org) {
        this.organizationsSelected.push(org);
      }
    } else {
      this.removeOrgSelected(orgId);
    }
  }

  removeOrgSelected(id: string) {
    const index = this.organizationsSelected.findIndex((org) => org.id === id);
    if (index !== -1) {
      this.organizationsSelected.splice(index, 1);
    }
  }

  orgIsSelected(orgId: string) {
    const org = this.organizationsSelected.find((org) => org.id == orgId);
    if (org) {
      return true;
    }
    return false;
  }

  getOrganizations(page: number, limit: number, search: string) {
    this.orgLoader = true;
    this.organizationService.getAllOrganizations(page, limit, search).subscribe(
      (response: any) => {
        this.organizations = response.records;
        this.orgLoader = false;
      },
      (error) => {
        this.organizations = [];
        this.orgLoader = false;
      }
    );
  }

  onUpSearch(search: any): void {
    if (search.length > 0) {
      this.getOrganizations(1, this.limit, search);
    }
  }

  onSubmit() {
    const formValue = this.groupForm.value;
    const group = {} as Group;

    group.name = formValue.name;
    group.description = formValue.description;
    group.organizations = [];

    this.organizationsSelected.forEach((org) => {
      group.organizations.push(org.id);
    });

    this.loading = true;

    // For update
    if (this.group?.id) {
      this.groupService.update(this.group?.id, group).subscribe(
        (data) => {
          this.loading = false;
          this.event.emit({ appId: data, res: 200 });
          this.groupForm.reset();
          this.onCloseDialog();
          this.service.show({
            title: 'Group',
            position: Position.TOP,
            type: Type.SUCCESS,
            message: 'updated successfully',
          });
        },
        (error) => {
          this.loading = false;
          this.service.show({
            title: 'Error Group',
            position: Position.TOP,
            type: Type.ERROR,
            message: error.error,
          });
        }
      );
    }
    // For create
    else {
      this.groupService.create(group).subscribe(
        async (data) => {
          this.loading = false;
          this.event.emit({ appId: data, res: 200 });
          this.groupForm.reset();
          this.onCloseDialog();
          this.service.show({
            title: 'Group',
            position: Position.TOP,
            type: Type.SUCCESS,
            message: 'created successfully',
          });
        },
        (error) => {
          this.loading = false;
          this.service.show({
            title: 'Error Group',
            position: Position.TOP,
            type: Type.ERROR,
            message: error.error,
          });
        }
      );
    }
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }
}
