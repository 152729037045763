<section class="main px-3">
  <div class="g-0">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="tableheading row">
        <div class="col-md-5 ">
          <div class="row">
            <div class="col">
              <span style="font-size: 14px;">
                List of organizations ({{ total.toString().padStart(2, "0") }})
              </span>
              <div class="mt-1">
                <input type="text" (keyup)="onUpSearch($event.target.value)" class="search"
                  placeholder="Search here..." />
              </div>
            </div>
          </div>
        </div>
        <div class="box_right col-md-7">
          <div class="row p-0">
            <div class="col-md-12 text-right p-0 m-0">
              <a (click)="onOpenFormModalOrganization()" class="btn-right blue mt-2">
                Create new organization
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Table -->
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-4">
        <div class="table_wrapper">
          <div class="content_table">
            <div class="table-responsive">
              <table class="table member-table">
                <thead>
                  <tr>
                    <th scope="col"><i class="bi-speedometer2"></i></th>
                    <th scope="col">Name</th>
                    <!-- <th scope="col">Customer Id</th> -->
                    <!-- <th scope="col">Subscription Id</th> -->
                    <th scope="col">Number User</th>
                    <th scope="col">Configured</th>
                    <th scope="col">Contacted</th>
                    <th scope="col">Subcription Type</th>
                    <th scope="col">Subscription Status</th>
                    <th scope="col" class="role">Action</th>
                  </tr>
                </thead>

                <tbody *ngIf="total > 0 && !organizationLoaded">
                  <tr
                    *ngFor="let organization of organizations">
                    <td>
                      <span class="circle" [ngStyle]="{ background: organization?.color }"></span>
                    </td>
                    <td>{{ organization?.name }}</td>
                    <!-- <td>{{ truncate(organization?.chargebeeCustomerId, 9) }}</td> -->
                    <!-- <td>{{ truncate(organization?.chargebeeSubscriptionId, 9) }}</td> -->
                    <td>{{ organization?.numberOfUser.toString().padStart(2, "0") }}</td>
                    <td>{{ organization?.configCompleted ? 'Yes' : 'No' }}</td>
                    <td>{{ organization?.isContacted ? 'Yes' : 'No' }}</td>
                    <td>{{ organization?.subscriptionType }}</td>
                    <td>{{ organization?.chargebeeSubscriptionStatus }}</td>
                    <td class="">
                      <div class="cancle">
                        <a (click)="onOpenFormModalOrganization(organization)">
                          <i class="bi-pencil-square edit mr-3"></i>
                        </a>
                        <i class="bi bi-x-lg" (click)="onOpenDeleteModal(template, organization)"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <spinner *ngIf="organizationLoaded"></spinner>
              <p *ngIf="organizationLoaded" class="no-app-found">Loading organizations</p>
              <p *ngIf="!organizationLoaded && total == 0" class="no-app-found">No organizations found.</p>
            </div>
            <div class="pcontrols mt-4" *ngIf="total > 0">
              <pagination [pageBtnClass]="'custom-btn-page'" (pageChanged)="pageChanged($event)" [(ngModel)]="page" [boundaryLinks]="true" [totalItems]="total"
                previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
              </pagination>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<ng-template #template>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="m-0 p-0 popup-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="onCloseDialog()">
        <i class="bi bi-x-lg"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="delete-popup">
        <div *ngIf="!cannotDelete">
          <h3 class="heading">Are you sure ?</h3>
          <div class="delete-text">
            Delete organization <br />« <strong>{{ organizationSelected.name }}</strong> »
          </div>
          <input (keyup)="onUpOrganizationName($event.target.value)" type="text" class="form-control"
            placeholder="Type name" />
          <br>
          <small>
            All organization-related resources will be permanently deleted.
          </small>
        </div>
        <div *ngIf="cannotDelete" class="text-center">
          <h3 class="heading" style="font-size: 20px;">Can't delete this organization </h3>
          <div style="line-height: 18px !important;">
            Before deleting the organization, check that the elements in <strong>Code</strong>,
            <strong>Environment</strong> and
            <strong>Cluster</strong> have been deleted.
          </div>
        </div>
        <div class="attachment-link mt-3" [class]="!cannotDelete ? 'd-flex justify-content-between': 'text-center'">
          <button *ngIf="!cannotDelete" class="btn-delete" type="button" [disabled]="disabledBtn"
            (click)="onSubmitDeleteOrganization()">
            Delete
          </button>
          <button class="btn-cancel" (click)="onCloseDialog()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #infoTemplate>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="m-0 p-0 popup-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="onCloseDialog()">
        <i class="bi bi-x-lg"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="delete-popup">
        <h3 class="heading">Info delete</h3>
        <div class="delete-text">
          Before deleting the organization, check that the elements in Code, Environment and Cluster have been deleted.
        </div>
        <button class="btn-cancel" (click)="onCloseDialog()">Close</button>
      </div>
    </div>
  </div>
</ng-template>
