import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Group } from 'src/app/data/model/group';
import { ShareService } from 'src/app/data/service/share.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  baseUrl: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };
  constructor(
    private httpClient: HttpClient,
    private shareService: ShareService
  ) {
    this.baseUrl = `${this.shareService.apiBaseURL}/api/v1/organizations/groups`;
  }

  create(organization: any): Observable<any> {
    const url = `${this.baseUrl}`;
    return this.httpClient.post(url, organization, this.httpOptions);
  }

  update(id: any, form: any): Observable<any> {
    const url = `${this.baseUrl}/${id}`;
    return this.httpClient.put(url, form, this.httpOptions);
  }

  delete(id: string): Observable<object> {
    const url = `${this.baseUrl}/${id}`;
    return this.httpClient.delete(url, this.httpOptions);
  }
  getAll(page?: any, limit?: any, search?: string): Observable<Group[]> {
    let url = `${this.baseUrl}?page=${page}&limit=${limit}`;
    if (search) {
      url = `${url}&limit=${search}`;
    }
    return this.httpClient.get<Group[]>(url, this.httpOptions);
  }
  getById(id: string): Observable<Group> {
    const url = `${this.baseUrl}/${id}`;
    return this.httpClient.get<Group>(url, this.httpOptions);
  }
}
