<div style="display:flex; flex-direction: column;text-align: center;margin-top: 25vh;" *ngIf="loading">
  <spinner [status]="status"></spinner>
  <span style="color: #043c66; font-size: 20px;">Loading deployment</span>
</div>

<section class="px-3" style="padding-top: 2rem;" *ngIf="!loading">
  <form [formGroup]="deploymentForm">
    <div class="row">
      <div class="col-md-12" style="padding-bottom: 20px;">
        <a class="back " [routerLink]="[ '/deploy']">
          <i class="bi-chevron-left"></i> &nbsp; Go back
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-md-9 offset-3" style="color: #043c66; font-size: 16px;">
        <strong>{{ deploymentForm.get('spec.nameOverride').value }}</strong>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-3">
        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <button *ngFor="let item of steps; let i=index" (click)="step=item.value" class="nav-link"
            [class.active]="step == item.value" [id]="'v-pills-tab-'+i+1" data-toggle="pill"
            [data-target]="'#v-pills-'+i+1" type="button" role="tab" [aria-controls]="'v-pills-'+i+1"
            aria-selected="true" [class.text-danger]="invalidStep(item.value)" [disabled]="disableStep(step, i)">
            {{item.label}}
            <i class="fa fa-warning text-danger ml-2" *ngIf="invalidStep(item.value)"></i>
          </button>
        </div>
      </div>

      <div class="col-9 wrapper-form">
        <div class="tab-content" id="v-pills-tabContent">
          <!-- pills-general -->
          <div class="tab-pane fade pt-3" [class.show]="step == 1" [class.active]="step == 1" id="v-pills-1"
            role="tabpanel" aria-labelledby="v-pills-tab-1">
            <div class="row mb-3">
              <!-- Name block -->
              <div class="col-md-7 form-group" formGroupName="spec">
                <div class="row">
                  <div class="col-md-3">
                    <label>Name*</label>
                  </div>
                  <div class="col-md-9">
                    <input class="form-control" placeholder="Deploy name" (blur)="onChangeName($event)"
                      formControlName="nameOverride" />
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="deploymentForm.get('spec.nameOverride').invalid && (deploymentForm.get('spec.nameOverride').touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Project block -->
              <div class="col-md-7">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-3">
                      <label>Project* <i *ngIf="loaderProject" class="fa fa-spinner fa-spin"></i></label>
                    </div>
                    <div class="col-md-9">

                      <app-select-search label="Project" placeholder="Choose a project" option-label="name"
                        option-value="id" [disable]="loaderProject || (deployment?.id && !duplicate)" [filtering]="true"
                        [options]="projects" formControlName="projectId" [options-visible]="loaderProject" (change)="onChangeProject($event)"
                        (filter)="onFilterProject($event)">
                        <div class="p-4" *ngIf="projects.length === 0">
                          <span style="cursor: pointer;">
                            Create a new project <a [routerLink]="[ '/onboard/projects']">Go here</a>
                          </span>
                        </div>
                      </app-select-search>
                      <div class="text-right d-flex justify-content-between mt-1"
                        *ngIf="deploymentForm.get('projectId').invalid && (deploymentForm.get('projectId').touched || formError)">
                        <small class="invalid-text">Invalid field</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Environment block -->
              <div class="col-md-7">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-3">
                      <label>Environment* <i *ngIf="loaderEnvironment" class="fa fa-spinner fa-spin"></i></label>
                    </div>
                    <div class="col-md-9">
                      <app-select-search label="Environment" placeholder="Choose an environment" option-label="name"
                        option-value="id"
                        [disable]="loaderEnvironment || !deploymentForm.get('projectId').value || (deployment?.id && !duplicate)"
                        [filtering]="true" [options]="environments" formControlName="environmentId"
                        [options-visible]="loaderEnvironment"
                        (change)="onChangeEnvironment($event)" (filter)="onFilterEnvironment($event)">
                        <div class="p-4" *ngIf="environments.length === 0">
                          <span style="cursor: pointer;">
                            Create a new environment <a
                               [routerLink]="['/onboard/projects/detail/'+deploymentForm.get('projectId').value]">Go
                              here</a>
                          </span>
                        </div>
                      </app-select-search>
                      <div class="text-right d-flex justify-content-between mt-1"
                        *ngIf="deploymentForm.get('environmentId').invalid && (deploymentForm.get('environmentId').touched || formError)">
                        <small class="invalid-text">Invalid field</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <!-- Image:Tag block -->
              <div class="col-md-7 form-group" formGroupName="spec">
                <div class="row">
                  <div class="col-md-3">
                    <label>Image:Tag*</label>
                  </div>
                  <div class="col-md-9">
                    <input class="form-control" placeholder="index.docker.io/my/image:v1.0.0"
                      [formControl]="imageTagFormControl" />
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="imageTagFormControl.invalid && (imageTagFormControl.touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                </div>
              </div>

              <!-- publicRegistry enabled block -->
              <div class="col-md-7 form-group">
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="usePublicRegistry"
                    formControlName="usePublicRegistry">
                  <label class="custom-control-label pt-1" for="usePublicRegistry">Use Public Registry</label>
                </div>
              </div>

              <!-- Registry block -->
              <div class="col-md-7 form-group" *ngIf="!deploymentForm.get('usePublicRegistry').value">
                <div class="row">
                  <div class="col-md-3">
                    <label>Registry Creds* <i *ngIf="loaderRegistry" class="fa fa-spinner fa-spin"></i></label>
                  </div>
                  <div class="col-md-9">
                    <app-select-search label="Registry" placeholder="Select registry creds" option-label="name"
                      option-value="id" [disable]="loaderRegistry" [filtering]="true" [options]="registries"
                      formControlName="registryId" [options-visible]="loaderRegistry"
                      (change)="onChangeRegistry($event)" (filter)="onFilterRegistry($event)">
                      <span style="cursor: pointer;" class="p-2"
                        *ngIf="registries.length === 0">
                        Create a new registry <a [routerLink]="[ '/registry']">Go here</a>
                      </span>
                    </app-select-search>
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="deploymentForm.get('registryId').invalid && (deploymentForm.get('registryId').touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Port block -->
              <div class="col-md-7 form-group" formGroupName="spec">
                <div class="row">
                  <div class="col-md-3">
                    <label>App Port*</label>
                  </div>
                  <div class="col-md-9">
                    <input class="form-control" formControlName="port" placeholder="8080" type="number" />
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="deploymentForm.get('spec.port').invalid && (deploymentForm.get('spec.port').touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- pills-Exposition -->
          <div class="tab-pane fade p-2" formGroupName="spec" id="v-pills-2" [class.show]="step == 2" [class.active]="step == 2" role="tabpanel" aria-labelledby="v-pills-tab-2">
            <div class="row">
              <!-- Expose block -->
              <div class="col-md-12 form-group">
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="appExposition" [formControl]="appExpositionControl">
                  <label class="custom-control-label pt-1" for="appExposition">Expose this application</label>
                </div>
              </div>
            </div>
            <div  formArrayName="ingress" *ngIf="getFormArray('spec.ingress').controls.length > 0 && appExpositionControl.value">
              <div class="row" *ngFor="let ingress of getFormArray('spec.ingress').controls; let i = index" [formGroupName]="i">
                <!-- Content ingress -->
                <div class="row">
                  <!-- Socket block -->
                  <div class="col-md-7 form-group">
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" [id]="'socketEnable'+i" formControlName="useSocket">
                      <label class="custom-control-label pt-1" [for]="'socketEnable'+i">Use Socket</label>
                    </div>
                  </div>

                  <!-- domain block -->
                  <div class="col-md-8 form-group">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-12">
                          <label> Host : {{ ingress.controls.host.value }} </label>
                        </div>
                        <div class="col-md-12">
                          <div class="d-flex">
                            <div style="width: 28%;">
                              <input type="text" placeholder="Subdomain" class="form-control" formControlName="subdomain" />
                              <div class="text-right d-flex justify-content-between mt-1"
                                *ngIf="ingress.controls.subdomain.invalid && (ingress.controls.subdomain.touched || formError)">
                                <small class="invalid-text">Invalid field</small>
                              </div>
                            </div>
                            <div style="width: 2%;" class="d-flex justify-content-center align-items-center mx-2">.</div>
                            <div style="width: 70%;">
                              <app-select-search label="Domain" placeholder="Choose a domain" option-label="host"
                                option-value="id" [disable]="ingress.controls.domainShared.value" [filtering]="false"
                                [options]="domains" formControlName="domainId" [options-visible]="ingress.controls.domainShared.value" (change)="onChangeDomain($event, i)"
                                (filter)="onFilterDomain($event)">
                                <div class="p-4" *ngIf="domains.length === 0">
                                  <span style="cursor: pointer;">
                                    Create a new domain <a [routerLink]="['/domain']">Go here</a>
                                  </span>
                                </div>
                              </app-select-search>
                              <div class="text-right d-flex justify-content-between mt-1"
                                *ngIf="ingress.controls.domain.invalid && (ingress.controls.domain.invalid.touched || formError)">
                                <small class="invalid-text">Invalid field</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Paths block -->
                  <div class="col-md-9 custom-fieldset mt-3" *ngIf="ingress.controls.paths.controls.length > 0">
                    <legend>Path</legend>
                    <div formGroupName="paths">
                      <div class="row custom-fieldset" *ngFor="let path of ingress.controls.paths.controls; let j = index" [formGroupName]="j">
                        <div class="col-md-8 form-group">
                          <input class="form-control" formControlName="path" placeholder="/your/path" />
                          <div class="text-right d-flex justify-content-between mt-1" *ngIf="path.controls.path.invalid && (path.controls.path.touched || formError)">
                            <small class="invalid-text">Invalid field</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-1" style="display: flex; justify-content: center;" *ngIf="i > 0">
                    <button (click)="onRemoveFormGroup('spec.ingress', i)" class="btn_delete">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>

                  <div class="col-md-12">
                    <hr style="color: #517abdba; height: 1px; width: 200px;margin-left: 41%;" />
                  </div>
                </div>

              </div>
            </div>

            <div class="row" *ngIf="!domainsShared">
              <div class="col-lg-12 col-md-12" *ngIf="deploymentForm.get('spec.ingress')?.controls.length > 0">
                <button type="button" [disabled]="deploymentForm.get('spec.ingress').invalid"
                  (click)="onAddFormGroup('spec.ingress')" class="add-button">
                  <i class="fa fa-plus"></i>&nbsp; Exposition
                </button>
              </div>
            </div>

          </div>

          <!-- pills-envVar -->
          <div class="tab-pane fade p-2" id="v-pills-3" formGroupName="spec" [class.show]="step == 3" [class.active]="step == 3"
            role="tabpanel" aria-labelledby="v-pills-tab-3">
            <div class="p-3 ">
              <label>Environments Variables <span style="color: red; font-size: 12px; font-weight: 500;">(Delete spaces
                  at beginning and end of key. Delete spaces in key. Remove special characters from key)</span></label>
              <div class="mb-5 mb-3 mt-2">
                <div class="row" formGroupName="env">
                  <div class="col-md-12 wrapper-env" *ngIf="getFormArray('spec.env').controls.length > 0">
                    <div class="row mb-2" *ngFor="let envVar of getFormArray('spec.env').controls; let i = index"
                      [formGroupName]="i" style="align-items: baseline;">
                      <!-- Key block -->
                      <div class="col-lg-5 col-md-5">
                        <div class="form-group" style="margin-bottom: 0px !important;">
                          <input type="text" formControlName="key" class="form-control" placeholder="Key" />
                          <div class="text-right d-flex justify-content-between mt-1">
                            <small *ngIf="envVar.controls.key.invalid && (envVar.controls.key.touched || formError)"
                              class="invalid-text">Invalid field</small>
                            <small
                              *ngIf="envVar.controls.key.hasError('duplicate') && (envVar.controls.key.touched || formError)"
                              class="invalid-text">Duplicate key</small>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6">
                        <div class="form-group" style="margin-bottom: 0px !important;">
                          <input type="text" formControlName="value" class="form-control" placeholder="Value" />
                          <div class="text-right d-flex justify-content-between mt-1">
                            <small *ngIf="envVar.controls.value.invalid && (envVar.controls.value.touched || formError)"
                              class="invalid-text">Invalid field</small>
                            <small
                              *ngIf="envVar.controls.value.hasError('duplicate') && (envVar.controls.value.touched || formError)"
                              class="invalid-text">Duplicate key</small>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1 col-md-1" style="display: flex; justify-content: center;">
                        <button (click)="onRemoveFormGroup('spec.env', i)" class="btn_delete">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">

                  <div class="col-md-12 col-xl-12 _actions" style="height: fit-content;"
                    *ngIf="getFormArray('spec.env')?.controls.length > 0">
                    <button class="btn_add" (click)="onAddFormGroup('spec.env')"
                      [disabled]="deploymentForm.get('spec.env').invalid" style="padding: 10px 10px;">
                      <i class="fa fa-plus"></i> Env Variable
                    </button>
                    <config-upload (envVars)="loadEnvironmentVariableFromFile($event)"></config-upload>
                  </div>

                  <div class="col-md-12 col-xl-12 _actions" style="height: fit-content;"
                    *ngIf="getFormArray('spec.env')?.controls.length == 0">
                    <div class="row" style="width: inherit;">
                      <div
                        style="display: flex; flex-direction: row; align-items: center;justify-content: center;height: fit-content;">
                        <button class="btn_add" (click)="onAddFormGroup('spec.env')"
                          [disabled]="deploymentForm.get('spec.env').invalid"
                          style="padding: 10px 10px; margin-left: 0px !important; width: 11vw;">
                          <i class="fa fa-plus"></i> Env Variable
                        </button>
                        <config-upload (envVars)="loadEnvironmentVariableFromFile($event)"></config-upload>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <hr style="color: #517abdba; height: 1px; width: 200px;margin-left: 41%;" />
                  </div>

                </div>
              </div>

              <label>Environment Variables from Secrets</label>
              <div class="mb-5 mb-3 mt-2" formGroupName="envFrom">
                <div class="wrapper-env" formArrayName="secrets">
                  <div class="row custom-fieldset"
                    *ngFor="let secret of getFormArray('spec.envFrom.secrets').controls; let i = index" [formGroupName]="i">

                    <div class="col-lg-11 col-md-11 form-group">
                      <input class="form-control" placeholder="My exists secret" formControlName="name" />
                      <div class="text-right d-flex justify-content-between mt-1"
                        *ngIf="secret.controls.name.invalid && (secret.controls.name.touched || formError)">
                        <small class="invalid-text">Invalid field</small>
                      </div>
                    </div>
                    <div class="col-lg-1 col-md-1 " style="display: flex; justify-content: center;">
                      <button (click)="onRemoveFormGroup('spec.envFrom.secrets', i)" class="btn_delete">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12" *ngIf="getFormArray('spec.envFrom.secrets')?.controls.length > 0">
                    <button type="button" [disabled]="deploymentForm.get('spec.envFrom.secrets').invalid"
                      (click)="onAddFormGroup('spec.envFrom.secrets')" class="add-button">
                      <i class="fa fa-plus"></i> &nbsp;&nbsp;Secret
                    </button>
                  </div>
                  <div class="col-md-12 col-xl-12" *ngIf="getFormArray('spec.envFrom.secrets')?.controls.length == 0">
                    <div class="row"
                      style="width: inherit;display: flex; flex-direction: row; align-items: center;justify-content: center;height: fit-content;">
                      <button type="button" [disabled]="deploymentForm.get('spec.envFrom.secrets').invalid"
                        (click)="onAddFormGroup('spec.envFrom.secrets')" class="add-button"
                        style="margin-left: 0px !important;width: 11vw;">
                        <i class="fa fa-plus"></i> &nbsp;&nbsp;Secret
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <hr style="color: #517abdba; height: 1px; width: 200px;margin-left: 41%;" />
                  </div>

                </div>
              </div>

              <label>Environment Variables from ConfigMaps</label>
              <div class="mt-2" formGroupName="envFrom">
                <div class="wrapper-env" formArrayName="configMaps">
                  <div class="row custom-fieldset mt-3"
                    *ngFor="let configMap of getFormArray('spec.envFrom.configMaps').controls; let i = index"
                    [formGroupName]="i">

                    <div class="col-lg-11 col-md-11 form-group mt-2">
                      <input class="form-control" formControlName="name" placeholder="My exists ConfigMap" />
                      <div class="text-right d-flex justify-content-between mt-1"
                        *ngIf="configMap.controls.name.invalid && (configMap.controls.name.touched || formError)">
                        <small class="invalid-text">Invalid field</small>
                      </div>
                    </div>
                    <div class="col-lg-1 col-md-1" style="display: flex; justify-content: center;">
                      <button (click)="onRemoveFormGroup('spec.envFrom.configMaps', i)" class="btn_delete">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12 col-xl-12" *ngIf="getFormArray('spec.envFrom.configMaps')?.controls.length > 0">
                    <button type="button" [disabled]="deploymentForm.get('spec.envFrom.configMaps').invalid"
                      (click)="onAddFormGroup('spec.envFrom.configMaps')" class="add-button">
                      <i class="fa fa-plus"></i>&nbsp; ConfigMap
                    </button>
                  </div>
                  <div class="col-md-12 col-xl-12" *ngIf="getFormArray('spec.envFrom.configMaps')?.controls.length == 0">
                    <div class="row"
                      style="width: inherit;display: flex; flex-direction: row; align-items: center;justify-content: center;height: fit-content;">
                      <button type="button" [disabled]="deploymentForm.get('spec.envFrom.configMaps').invalid"
                        (click)="onAddFormGroup('spec.envFrom.configMaps')" class="add-button"
                        style="margin-left: 0px !important;width: 11vw;">
                        <i class="fa fa-plus"></i>&nbsp; ConfigMap
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- pills-hpaResource -->
          <div class="tab-pane fade p-3" id="v-pills-4" formGroupName="spec" [class.show]="step == 4" [class.active]="step == 4"
            role="tabpanel" aria-labelledby="v-pills-tab-4">
            <div class="">
              <span>HPA / Resources</span>
              <div class="row my-2" formGroupName="autoscaling">

                <!-- Enabled -->
                <div class="col-md-12 form-group mt-4">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="enableHpa" formControlName="enabled"
                      (click)="onChangeEnableCheckbox($event, 'spec.autoscaling')">
                    <label class="custom-control-label pt-1" for="enableHpa">Enable HPA</label>
                  </div>
                </div>

                <!-- Min Replicas -->
                <div class="col-md-6 mb-3" *ngIf="deploymentForm.get('spec.autoscaling.enabled').value">
                  <div class="form-group">
                    <label for="name">Min Replicas*</label>
                    <input formControlName="minReplicas" class="form-control" type="number" placeholder="1">
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="deploymentForm.get('spec.autoscaling.minReplicas').invalid && (deploymentForm.get('spec.autoscaling.minReplicas').touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                </div>

                <!-- Target CPU Utilization -->
                <div class="col-md-6 mb-3" *ngIf="deploymentForm.get('spec.autoscaling.enabled').value">
                  <div class="form-group">
                    <label for="name">Target CPU Utilization*</label>
                    <div class="d-flex custom-group">
                      <input class="form-control" formControlName="targetCPUUtilizationPercentage" placeholder="75"
                        type="number" />
                      <div class="form-control d-flex align-items-center justify-content-center">%</div>
                    </div>
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="deploymentForm.get('spec.autoscaling.targetCPUUtilizationPercentage').invalid && (deploymentForm.get('spec.autoscaling.targetCPUUtilizationPercentage').touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                </div>

                <!-- Max Replicas -->
                <div class="col-md-6 mb-3" *ngIf="deploymentForm.get('spec.autoscaling.enabled').value">
                  <div class="form-group">
                    <label for="name">Max Replica*</label>
                    <input formControlName="maxReplicas" class="form-control" type="number" placeholder="1">
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="deploymentForm.get('spec.autoscaling.maxReplicas').invalid && (deploymentForm.get('spec.autoscaling.maxReplicas').touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                </div>

                <!-- Target Memory Utilization -->
                <div class="col-md-6 mb-3" *ngIf="deploymentForm.get('spec.autoscaling.enabled').value">
                  <div class="form-group">
                    <label for="name">Target Memory Utilization</label>
                    <div class="d-flex custom-group">
                      <input class="form-control" formControlName="targetMemoryUtilizationPercentage" placeholder="75"
                        type="number" />
                      <div class="form-control d-flex align-items-center justify-content-center">%</div>
                    </div>
                  </div>
                </div>
              </div>

              <span>Resources</span>
              <!-- resourceType -->
              <div class="row mt-4">
                <div class="col-md-6 form-group">
                  <label>Type</label>
                  <select [formControl]="resourcesTypeFormControl" class="form-control">
                    <option *ngFor="let resourceType of resourcesTypes; let i=index" [value]="resourceType.value">
                      {{ resourceType.label }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- CPU -->
              <div class="row mt-3" formGroupName="resources" *ngIf="resourcesTypeFormControl.value == 'cpu'">
                <!-- Cpu Request -->
                <div class="col-md-6">
                  <div class="form-group" formGroupName="requests">
                    <label for="name">Cpu Request*</label>
                    <div class="d-flex custom-group">
                      <input class="form-control" placeholder="Allocated Request CPU" type="number"
                        formControlName="cpu" />
                      <select class="form-control" [formControl]="requestCpuFormControl">
                        <option *ngFor="let cpuUnit of cpuUnits" [value]="cpuUnit.value">
                          {{ cpuUnit.key }}
                        </option>
                      </select>
                    </div>
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="deploymentForm.get('spec.resources.requests.cpu').invalid && (deploymentForm.get('spec.resources.requests.cpu').touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                </div>

                <!-- Memory Request -->
                <div class="col-md-6">
                  <div class="form-group" formGroupName="requests">
                    <label for="name">Memory Request*</label>
                    <div class="d-flex custom-group">
                      <input class="form-control" placeholder="Allocated Request Memory" formControlName="memory"
                        type="number" />
                      <select class="form-control" [formControl]="requestMemoryFormControl">
                        <option *ngFor="let memoryUnit of memoryUnits" [value]="memoryUnit.value">
                          {{ memoryUnit.key }}
                        </option>
                      </select>
                    </div>
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="deploymentForm.get('spec.resources.requests.memory').invalid && (deploymentForm.get('spec.resources.requests.memory').touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                </div>

                <!-- Cpu Limit -->
                <div class="col-md-6">
                  <div class="form-group" formGroupName="limits">
                    <label for="name">Cpu Limit*</label>
                    <div class="d-flex custom-group">
                      <input class="form-control" placeholder="Allocated Limit CPU" formControlName="cpu"
                        type="number" />
                      <select class="form-control" [formControl]="limitCpuFormControl">
                        <option *ngFor="let cpuUnit of cpuUnits" [value]="cpuUnit.value">
                          {{ cpuUnit.key }}
                        </option>
                      </select>
                    </div>
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="deploymentForm.get('spec.resources.limits.cpu').invalid && (deploymentForm.get('spec.resources.limits.cpu').touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                </div>

                <!-- Memory Limit -->
                <div class="col-md-6">
                  <div class="form-group" formGroupName="limits">
                    <label for="name">Memory Limit*</label>
                    <div class="d-flex custom-group">
                      <input class="form-control" placeholder="Allocated Limit Memory" type="number"
                        formControlName="memory" />
                      <select class="form-control" [formControl]="limitMemoryFormControl">
                        <option *ngFor="let memoryUnit of memoryUnits" [value]="memoryUnit.value">
                          {{ memoryUnit.key }}
                        </option>
                      </select>
                    </div>
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="deploymentForm.get('spec.resources.limits.memory').invalid && (deploymentForm.get('spec.resources.limits.memory').touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                </div>
              </div>

              <!-- GPU -->
              <div class="row mt-3" formGroupName="resources" *ngIf="resourcesTypeFormControl.value == 'gpu'">
                <!-- Gpu Request -->
                <div class="col-md-6">
                  <div class="form-group" formGroupName="requests">
                    <label for="name">Gpu Request*</label>
                    <div class="d-flex custom-group">
                      <input class="form-control" (input)="onChangeResourceRequestGpu($event)" placeholder="Allocated Request GPU" type="number" formControlName="gpu" />
                      <select class="form-control" disabled>
                        <option value="GPU">GPU</option>
                      </select>
                    </div>
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="deploymentForm.get('spec.resources.requests.gpu').invalid && (deploymentForm.get('spec.resources.requests.gpu').touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                </div>

                <!-- GPU Limit -->
                <div class="col-md-6">
                  <div class="form-group" formGroupName="limits">
                    <label for="name">GPU Limit*</label>
                    <div class="d-flex custom-group">
                      <input class="form-control" placeholder="Allocated Limit GPU" type="number" formControlName="gpu" />
                      <select class="form-control" disabled>
                        <option value="GPU">GPU</option>
                      </select>
                    </div>
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="deploymentForm.get('spec.resources.limits.gpu').invalid && (deploymentForm.get('spec.resources.limits.gpu').touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- pills-volumes -->
          <div class="tab-pane fade p-3" id="v-pills-5" formGroupName="spec" [class.show]="step == 5" [class.active]="step == 5"
            role="tabpanel" aria-labelledby="v-pills-tab-5">
            <div formGroupName="volumes">
              <label>Empty Dirs</label>
              <div class="mb-5" formArrayName="emptyDirs">
                <div class="wrapper-env">
                  <div class="row custom-fieldset mt-3"
                    *ngFor="let emptyDir of getFormArray('spec.volumes.emptyDirs').controls; let i = index"
                    [formGroupName]="i">

                    <div class="col-md-6">
                      <div class="form-group">
                        <input class="form-control" formControlName="name" placeholder="Name" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="emptyDir.controls.name.invalid && (emptyDir.controls.name.touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="form-group">
                        <input class="form-control" formControlName="mountPath" placeholder="Mount Path Ex:/my/path" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="emptyDir.controls.mountPath.invalid && (emptyDir.controls.mountPath.touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-1 col-md-1" style="display: flex; justify-content: center;">
                      <button (click)="onRemoveFormGroup('spec.volumes.emptyDirs', i)" class="btn_delete">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-12 col-md-12" *ngIf="deploymentForm.get('spec.volumes.emptyDirs')?.controls.length > 0">
                    <button type="button" [disabled]="deploymentForm.get('spec.volumes.emptyDirs').invalid"
                      (click)="onAddFormGroup('spec.volumes.emptyDirs')" class="add-button">
                      <i class="fa fa-plus"></i>&nbsp; Empty Dir
                    </button>
                  </div>
                  <div class="col-md-12 col-xl-12"
                    *ngIf="deploymentForm.get('spec.volumes.emptyDirs')?.controls.length == 0">
                    <div class="row"
                      style="width: inherit;display: flex; flex-direction: row; align-items: center;justify-content: center;height: fit-content;">
                      <button type="button" [disabled]="deploymentForm.get('spec.volumes.emptyDirs').invalid"
                        (click)="onAddFormGroup('spec.volumes.emptyDirs')" class="add-button"
                        style="margin-left: 0px !important;width: 11vw;">
                        <i class="fa fa-plus"></i>&nbsp; Empty Dir
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <hr style="color: #517abdba; height: 1px; width: 200px;margin-left: 41%;" />
                  </div>

                </div>
              </div>

              <label>Config Maps</label>
              <div class="mb-5" formArrayName="configMaps">
                <div class="wrapper-env mt-3">
                  <div *ngFor="let configMap of getFormArray('spec.volumes.configMaps').controls; let i = index"
                    [formGroupName]="i">
                    <div class="row custom-fieldset">
                      <div class="col-md-4 form-group">
                        <input class="form-control" formControlName="name" placeholder="Name" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="configMap.controls.name.invalid && (configMap.controls.name.touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                      <div class="col-md-3 form-group">
                        <input class="form-control" formControlName="configMapRefName" placeholder="Ref Name" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="configMap.controls.configMapRefName.invalid && (configMap.controls.configMapRefName.touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                      <div class="col-md-4 form-group">
                        <input class="form-control" formControlName="mountPath" placeholder="Mount Path Ex:/my/path" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="configMap.controls.mountPath.invalid && (configMap.controls.mountPath.touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                      <div class="col-lg-1 col-md-1" style="display: flex; justify-content: center;">
                        <button (click)="onRemoveFormGroup('spec.volumes.configMaps', i)" class="btn_delete">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12" *ngIf="deploymentForm.get('spec.volumes.configMaps')?.controls.length > 0">
                    <button type="button" [disabled]="deploymentForm.get('spec.volumes.configMaps').invalid"
                      (click)="onAddFormGroup('spec.volumes.configMaps')" class="add-button">
                      <i class="fa fa-plus"></i>&nbsp; Config Map
                    </button>
                  </div>
                  <div class="col-md-12 col-xl-12"
                    *ngIf="deploymentForm.get('spec.volumes.configMaps')?.controls.length == 0">
                    <div class="row"
                      style="width: inherit;display: flex; flex-direction: row; align-items: center;justify-content: center;height: fit-content;">
                      <button type="button" [disabled]="deploymentForm.get('spec.volumes.configMaps').invalid"
                        (click)="onAddFormGroup('spec.volumes.configMaps')" class="add-button"
                        style="margin-left: 0px !important;width: 11vw;">
                        <i class="fa fa-plus"></i>&nbsp; Config Map
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <hr style="color: #517abdba; height: 1px; width: 200px;margin-left: 41%;" />
                  </div>

                </div>
              </div>

              <label>Secrets</label>
              <div class="mb-5" formArrayName="secrets">
                <div class="wrapper-env mt-3">
                  <div *ngFor="let secret of getFormArray('spec.volumes.secrets').controls; let i = index"
                    [formGroupName]="i">

                    <div class="row custom-fieldset">
                      <div class="col-md-4 form-group">
                        <input class="form-control" formControlName="name" placeholder="Name" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="secret.controls.name.invalid && (secret.controls.name.touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                      <div class="col-md-3 form-group">
                        <input class="form-control" formControlName="secretRefName" placeholder="Ref Name" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="secret.controls.secretRefName.invalid && (secret.controls.secretRefName.touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                      <div class="col-md-4 form-group">
                        <input class="form-control" formControlName="mountPath" placeholder="Mount Path Ex:/my/path" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="secret.controls.mountPath.invalid && (secret.controls.mountPath.touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                      <div class="col-lg-1 col-md-1" style="display: flex; justify-content: center;">
                        <button (click)="onRemoveFormGroup('spec.volumes.secrets', i)" class="btn_delete">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-12" *ngIf="deploymentForm.get('spec.volumes.secrets')?.controls.length > 0">
                    <button type="button" [disabled]="deploymentForm.get('spec.volumes.secrets').invalid"
                      (click)="onAddFormGroup('spec.volumes.secrets')" class="add-button">
                      <i class="fa fa-plus"></i> &nbsp;Secret
                    </button>
                  </div>
                  <div class="col-md-12 col-xl-12" *ngIf="deploymentForm.get('spec.volumes.secrets')?.controls.length == 0">
                    <div class="row"
                      style="width: inherit;display: flex; flex-direction: row; align-items: center;justify-content: center;height: fit-content;">
                      <button type="button" [disabled]="deploymentForm.get('spec.volumes.secrets').invalid"
                        (click)="onAddFormGroup('spec.volumes.secrets')" class="add-button"
                        style="margin-left: 0px !important;width: 11vw;">
                        <i class="fa fa-plus"></i> &nbsp;Secret
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <hr style="color: #517abdba; height: 1px; width: 200px;margin-left: 41%;" />
                  </div>

                </div>
              </div>

              <label>PVC</label>
              <div formArrayName="pvcs">
                <div class="wrapper-env mt-3">
                  <div *ngFor="let pvc of getFormArray('spec.volumes.pvcs').controls; let i = index" [formGroupName]="i">

                    <div class="row">
                      <div class="col-lg-12 col-md-12">
                        <hr style="color: #517abdba; height: 1px; width: 200px;margin-left: 45%;" *ngIf="i > 0" />
                      </div>
                      <div class="col-md-12">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input" (click)="onChangeExistingPvc($event, i)"
                            [id]="'existingPvcs'+i" formControlName="existingPvc">
                          <label class="custom-control-label pt-1" [for]="'existingPvcs'+i">Use Existing PVC</label>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 form-group">
                        <input class="form-control" formControlName="name" placeholder="Name" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="pvc.controls.name.invalid && (pvc.controls.name.touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 form-group">
                        <input class="form-control" formControlName="mountPath" placeholder="Mount Path Ex:/my/path" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="pvc.controls.mountPath.invalid && (pvc.controls.mountPath.touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 form-group" *ngIf="!pvc.controls.existingPvc.value">
                        <input class="form-control" formControlName="storageClass" placeholder="Storage Class" />
                      </div>
                      <div class="col-lg-6 col-md-6" *ngIf="!pvc.controls.existingPvc.value">
                        <div class="form-group" formArrayName="accessModes">
                          <label>Access Mode</label>
                          <select class="form-control" formControlName="0">
                            <option *ngFor="let accessMode of accessModes" [value]="accessMode">
                              {{ accessMode }}
                            </option>
                          </select>
                          <div class="text-right d-flex justify-content-between mt-1"
                            *ngIf="pvc.controls.accessModes.controls[0].invalid && (pvc.controls.accessModes.controls[0].touched || formError)">
                            <small class="invalid-text">Invalid field</small>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-5 col-md-5" formGroupName="resources" *ngIf="!pvc.controls.existingPvc.value">
                        <div formGroupName="requests">
                          <div class="form-group">
                            <label>Storage Size</label>
                            <div class="d-flex custom-group">
                              <input class="form-control" formControlName="storage" placeholder="Size" type="number" />
                              <select class="form-control" [id]="'requestsStorage'+i"
                                (change)="onChangeStorage($event, i)">
                                <option *ngFor="let memoryUnit of memoryUnits" [value]="memoryUnit.value">
                                  {{ memoryUnit.key }}
                                </option>
                              </select>
                            </div>
                            <div class="text-right d-flex justify-content-between mt-1"
                              *ngIf="pvc.get('resources.requests.storage').invalid && pvc.get('resources.requests.storage').value">
                              <small class="invalid-text">Invalid field</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1 col-md-1" style="display: flex; justify-content: center;">
                        <button (click)="onRemoveFormGroup('spec.volumes.pvcs', i)" class="btn_delete">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12" *ngIf="deploymentForm.get('spec.volumes.pvcs')?.controls.length > 0">
                    <button type="button" [disabled]="deploymentForm.get('spec.volumes.pvcs').invalid"
                      (click)="onAddFormGroup('spec.volumes.pvcs')" class="add-button">
                      <i class="fa fa-plus"></i>&nbsp; PVC
                    </button>
                  </div>
                  <div class="col-md-12 col-xl-12" *ngIf="deploymentForm.get('spec.volumes.pvcs')?.controls.length == 0">
                    <div class="row"
                      style="width: inherit;display: flex; flex-direction: row; align-items: center;justify-content: center;height: fit-content;">
                      <button type="button" [disabled]="deploymentForm.get('spec.volumes.pvcs').invalid"
                        (click)="onAddFormGroup('spec.volumes.pvcs')" class="add-button"
                        style="margin-left: 0px !important;width: 11vw;">
                        <i class="fa fa-plus"></i>&nbsp; PVC
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- pills-probes -->
          <div class="tab-pane fade" id="v-pills-6" formGroupName="spec" [class.show]="step == 6" [class.active]="step == 6" role="tabpanel"
            aria-labelledby="v-pills-tab-6">
            <div class="row py-3 probes" formGroupName="probes">
              <fieldset class="custom-fieldset" formGroupName="livenessProbe">
                <div class="col-md-12 form-group">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="enabledLivenessProbe"
                      formControlName="enabled" (click)="onChangeEnableCheckbox($event, 'spec.probes.livenessProbe')">
                    <label class="custom-control-label pt-1" for="enabledLivenessProbe">Use Liveness Probe</label>
                  </div>
                </div>
                <div class="col-md-12" *ngIf="deploymentForm.get('spec.probes.livenessProbe.enabled').value">
                  <!-- type -->
                  <div class="row">
                    <div class="col-md-4">
                      <label>Type</label>
                    </div>
                    <div class="col-md-8 form-group">
                      <select class="form-control" [formControl]="livenessTypeFormControl">
                        <option *ngFor="let probesType of probesTypes; let i=index" [value]="probesType.value">
                          {{ probesType.label }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- tcpSocket.port -->
                  <div class="form-group" *ngIf="livenessTypeFormControl.value == 'tcpSocket'">
                    <div class="row">
                      <div class="col-md-4">
                        <label>TCP Socket Port</label>
                      </div>
                      <div class="col-md-8" formGroupName="tcpSocket">
                        <!-- port -->
                        <input class="form-control" formControlName="port" type="number"
                          placeholder="TCP Socket Port" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="deploymentForm.get('spec.probes.livenessProbe.tcpSocket.port').invalid && (deploymentForm.get('spec.probes.livenessProbe.tcpSocket.port').touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- exec.command -->
                  <div class="form-group" *ngIf="livenessTypeFormControl.value == 'exec'">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Exec Command</label>
                      </div>
                      <div class="col-md-8">
                        <div class="d-flex">
                          <input class="form-control" (blur)="onBlurLivenessExecCommandFormControl()"
                            [class.input-red]="livenessExecCommandFormControl.value == undefined"
                            placeholder="New command" [formControl]="livenessExecCommandFormControl" type="text">
                          <button style="width: 50px;" type="button"
                            (click)="onAddElement('spec.probes.livenessProbe.exec.command')" class="btn-add-cmd px-3">
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="deploymentForm.get('spec.probes.livenessProbe.exec.command').invalid && (deploymentForm.get('spec.probes.livenessProbe.exec.command').touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                        <div class="mt-1">
                          <span
                            *ngFor="let item of getFormArray('spec.probes.livenessProbe.exec.command').controls; let i=index;"
                            class="badge badge-secondary mr-2 mb-1">
                            {{ item.value }}
                            <i class="fa fa-times"
                              (click)="onRemoveElement('spec.probes.livenessProbe.exec.command', i)"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- httpGet.port httpGet.path -->
                  <div class="row" formGroupName="httpGet" *ngIf="livenessTypeFormControl.value == 'httpGet'">
                    <!-- httpGet.path -->
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-4">
                          <label>HTTP Get Path</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="path" placeholder="/healthz" />
                          <div class="text-right d-flex justify-content-between mt-1"
                            *ngIf="deploymentForm.get('spec.probes.livenessProbe.httpGet.path').invalid && (deploymentForm.get('spec.probes.livenessProbe.httpGet.path').touched || formError)">
                            <small class="invalid-text">Invalid field</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- httpGet.port -->
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-4">
                          <label>HTTP Get Port</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="port" placeholder="8080" type="number" />
                          <div class="text-right d-flex justify-content-between mt-1"
                            *ngIf="deploymentForm.get('spec.probes.livenessProbe.httpGet.port').invalid && (deploymentForm.get('spec.probes.livenessProbe.httpGet.port').touched || formError)">
                            <small class="invalid-text">Invalid field</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- initialDelaySeconds -->
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Initial Delay Seconds</label>
                      </div>
                      <div class="col-md-8">
                        <input class="form-control" formControlName="initialDelaySeconds" type="number"
                          placeholder="5" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="deploymentForm.get('spec.probes.livenessProbe.initialDelaySeconds').invalid && (deploymentForm.get('spec.probes.livenessProbe.initialDelaySeconds').touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- periodSeconds -->
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Period Seconds</label>
                      </div>
                      <div class="col-md-8">
                        <input class="form-control" formControlName="periodSeconds" type="number" placeholder="10" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="deploymentForm.get('spec.probes.livenessProbe.periodSeconds').invalid && (deploymentForm.get('spec.probes.livenessProbe.periodSeconds').touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- advance -->
                  <div class="form-group">
                    <label>
                      <button class="others-conf" (click)="livenessAdvance=!livenessAdvance">
                        Advance <i class="fa" [class.fa-caret-up]="livenessAdvance"
                          [class.fa-caret-down]="!livenessAdvance"></i>
                      </button>
                    </label>
                  </div>

                  <!-- timeoutSeconds -->
                  <div class="form-group" *ngIf="livenessAdvance">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Timeout Seconds</label>
                      </div>
                      <div class="col-md-8">
                        <input class="form-control" formControlName="timeoutSeconds" type="number"
                          placeholder="Timeout Seconds" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="deploymentForm.get('spec.probes.livenessProbe.timeoutSeconds').invalid && (deploymentForm.get('spec.probes.livenessProbe.timeoutSeconds').touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- successThreshold -->
                  <div class="form-group" *ngIf="livenessAdvance">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Success Threshold</label>
                      </div>
                      <div class="col-md-8">
                        <input class="form-control" formControlName="successThreshold" type="number"
                          placeholder="Success Threshold" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="deploymentForm.get('spec.probes.livenessProbe.successThreshold').invalid && (deploymentForm.get('spec.probes.livenessProbe.successThreshold').touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- failureThreshold -->
                  <div class="form-group" *ngIf="livenessAdvance">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Failure Threshold</label>
                      </div>
                      <div class="col-md-8">
                        <input class="form-control" formControlName="failureThreshold" type="number"
                          placeholder="Failure Threshold" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="deploymentForm.get('spec.probes.livenessProbe.failureThreshold').invalid && (deploymentForm.get('spec.probes.livenessProbe.failureThreshold').touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- httpGet.httpHeaders -->
                  <div class="form-group" *ngIf="livenessAdvance && livenessTypeFormControl.value == 'httpGet'">
                    <div class="row">
                      <div class="col-md-4">
                        <label>HTTP Get Headers</label>
                      </div>
                      <div class="col-md-8">
                        <div class="d-flex">
                          <input class="form-control"
                            [class.input-red]="livenessProbeHeadersNameFormControl.value == undefined"
                            [formControl]="livenessProbeHeadersNameFormControl" style="margin-right: 8px !important;"
                            placeholder="Name" type="text" />
                          <input class="form-control"
                            [class.input-red]="livenessProbeHeadersValueFormControl.value == undefined"
                            [formControl]="livenessProbeHeadersValueFormControl" placeholder="Value" type="text" />
                          <button style="width: 50px;"
                            (click)="onAddElement('spec.probes.livenessProbe.httpGet.httpHeaders')" class="btn-add-cmd px-3">
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                        <div class="mt-1">
                          <span
                            *ngFor="let item of getFormArray('spec.probes.livenessProbe.httpGet.httpHeaders').controls; let i=index;"
                            class="badge badge-secondary mr-2 mb-1">
                            {{ item.controls.name.value }}:{{ item.controls.value.value }}
                            <i (click)="onRemoveElement('spec.probes.livenessProbe.httpGet.httpHeaders', i)"
                              class="fa fa-times"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </fieldset>

              <fieldset class="custom-fieldset mt-3" formGroupName="readinessProbe">
                <div class="col-md-12 form-group">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="enabledReadynessProbe"
                      formControlName="enabled" (click)="onChangeEnableCheckbox($event, 'spec.probes.readinessProbe')">
                    <label class="custom-control-label pt-1" for="enabledReadynessProbe">Use Readiness probe</label>
                  </div>
                </div>

                <div class="col-md-12" *ngIf="deploymentForm.get('spec.probes.readinessProbe.enabled').value">

                  <!-- type -->
                  <div class="row">
                    <div class="col-md-4">
                      <label>Type</label>
                    </div>
                    <div class="col-md-8 form-group">
                      <select class="form-control" [formControl]="readinessTypeFormControl">
                        <option *ngFor="let probesType of probesTypes; let i=index" [value]="probesType.value">
                          {{ probesType.label }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- tcpSocket.port -->
                  <div class="form-group" *ngIf="readinessTypeFormControl.value == 'tcpSocket'">
                    <div class="row">
                      <div class="col-md-4">
                        <label>TCP Socket Port</label>
                      </div>
                      <div class="col-md-8" formGroupName="tcpSocket">
                        <!-- port -->
                        <input class="form-control" formControlName="port" type="number"
                          placeholder="TCP Socket Port" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="deploymentForm.get('spec.probes.readinessProbe.tcpSocket.port').invalid && (deploymentForm.get('spec.probes.readinessProbe.tcpSocket.port').touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- exec.command -->
                  <div class="form-group" *ngIf="readinessTypeFormControl.value == 'exec'">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Exec Command</label>
                      </div>
                      <div class="col-md-8">
                        <div class="d-flex">
                          <input class="form-control" (blur)="onBlurReadinessExecCommandFormControl()"
                            [class.input-red]="readinessExecCommandFormControl.value == undefined"
                            placeholder="New command" [formControl]="readinessExecCommandFormControl" type="text">
                          <button type="button" style="width: 50px;"
                            (click)="onAddElement('spec.probes.readinessProbe.exec.command')" class="btn-add-cmd px-3">
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="deploymentForm.get('spec.probes.readinessProbe.exec.command').invalid && (deploymentForm.get('spec.probes.readinessProbe.exec.command').touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                        <div class="mt-1">
                          <span
                            *ngFor="let item of getFormArray('spec.probes.readinessProbe.exec.command').controls; let i=index;"
                            class="badge badge-secondary mr-2 mb-1">
                            {{ item.value }}
                            <i class="fa fa-times"
                              (click)="onRemoveElement('spec.probes.readinessProbe.exec.command', i)"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- httpGet.path httpGet.port -->
                  <div class="row" formGroupName="httpGet" *ngIf="readinessTypeFormControl.value == 'httpGet'">

                    <!-- httpGet.path -->
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-4">
                          <label>HTTP Get Path</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="path" placeholder="/ready" />
                          <div class="text-right d-flex justify-content-between mt-1"
                            *ngIf="deploymentForm.get('spec.probes.readinessProbe.httpGet.path').invalid && (deploymentForm.get('spec.probes.readinessProbe.httpGet.path').touched || formError)">
                            <small class="invalid-text">Invalid field</small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- httpGet.port -->
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-4">
                          <label>HTTP Get Port</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="port" type="number" placeholder="8080" />
                          <div class="text-right d-flex justify-content-between mt-1"
                            *ngIf="deploymentForm.get('spec.probes.readinessProbe.httpGet.port').invalid && (deploymentForm.get('spec.probes.readinessProbe.httpGet.port').touched || formError)">
                            <small class="invalid-text">Invalid field</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- initialDelaySeconds -->
                  <div class="row">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Initial Delay Seconds</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="initialDelaySeconds" type="number"
                            placeholder="10" />
                          <div class="text-right d-flex justify-content-between mt-1"
                            *ngIf="deploymentForm.get('spec.probes.readinessProbe.initialDelaySeconds').invalid && (deploymentForm.get('spec.probes.readinessProbe.initialDelaySeconds').touched || formError)">
                            <small class="invalid-text">Invalid field</small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- periodSeconds -->
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Period Seconds</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="periodSeconds" type="number" placeholder="5" />
                          <div class="text-right d-flex justify-content-between mt-1"
                            *ngIf="deploymentForm.get('spec.probes.readinessProbe.periodSeconds').invalid && (deploymentForm.get('spec.probes.readinessProbe.periodSeconds').touched || formError)">
                            <small class="invalid-text">Invalid field</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Advance -->
                  <div class="form-group">
                    <label>
                      <button class="others-conf" (click)="readinessAdvance=!readinessAdvance">
                        Advance <i class="fa" [class.fa-caret-up]="readinessAdvance"
                          [class.fa-caret-down]="!readinessAdvance"></i>
                      </button>
                    </label>
                  </div>

                  <!-- timeoutSeconds -->
                  <div class="form-group" *ngIf="readinessAdvance">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Timeout Seconds</label>
                      </div>
                      <div class="col-md-8">
                        <input class="form-control" formControlName="timeoutSeconds" type="number"
                          placeholder="Timeout Seconds" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="deploymentForm.get('spec.probes.readinessProbe.timeoutSeconds').invalid && (deploymentForm.get('spec.probes.readinessProbe.timeoutSeconds').touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- successThreshold -->
                  <div class="form-group" *ngIf="readinessAdvance">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Success Threshold</label>
                      </div>
                      <div class="col-md-8">
                        <input class="form-control" formControlName="successThreshold" type="number"
                          placeholder="Success Threshold" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="deploymentForm.get('spec.probes.readinessProbe.successThreshold').invalid && (deploymentForm.get('spec.probes.readinessProbe.successThreshold').touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- failureThreshold -->
                  <div class="form-group" *ngIf="readinessAdvance">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Failure Threshold</label>
                      </div>
                      <div class="col-md-8">
                        <input class="form-control" formControlName="failureThreshold" type="number"
                          placeholder="Failure Threshold" />
                        <div class="text-right d-flex justify-content-between mt-1"
                          *ngIf="deploymentForm.get('spec.probes.readinessProbe.failureThreshold').invalid && (deploymentForm.get('spec.probes.readinessProbe.failureThreshold').touched || formError)">
                          <small class="invalid-text">Invalid field</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- httpGet.httpHeaders -->
                  <div class="form-group" *ngIf="readinessAdvance && readinessTypeFormControl.value == 'httpGet'">
                    <div class="row">
                      <div class="col-md-4">
                        <label>HTTP Get Headers</label>
                      </div>
                      <div class="col-md-8">
                        <div class="d-flex">
                          <input class="form-control"
                            [class.input-red]="readinessProbeHeadersNameFormControl.value == undefined"
                            [formControl]="readinessProbeHeadersNameFormControl" style="margin-right: 8px !important;"
                            placeholder="Name" type="text" />
                          <input class="form-control"
                            [class.input-red]="readinessProbeHeadersValueFormControl.value == undefined"
                            [formControl]="readinessProbeHeadersValueFormControl" placeholder="Value" type="text" />
                          <button style="width: 50px;"
                            (click)="onAddElement('spec.probes.readinessProbe.httpGet.httpHeaders')"
                            class="btn-add-cmd px-3">
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                        <div class="mt-1">
                          <span
                            *ngFor="let item of getFormArray('spec.probes.readinessProbe.httpGet.httpHeaders').controls; let i=index;"
                            class="badge badge-secondary mr-2 mb-1">
                            {{ item.controls.name.value }}:{{ item.controls.value.value }}
                            <i (click)="onRemoveElement('spec.probes.readinessProbe.httpGet.httpHeaders', i)"
                              class="fa fa-times"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </fieldset>
            </div>
          </div>

          <!-- pills-livecycles-commands-args -->
          <div class="tab-pane fade p-3" formGroupName="spec" id="v-pills-7" [class.show]="step == 7" [class.active]="step == 7"
            role="tabpanel" aria-labelledby="v-pills-tab-7">
            <!-- Commands Section -->
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Commands <small>Fill to input before clic on button <i class="fa fa-plus"></i></small></label>
                  <div class="d-flex">
                    <input class="form-control" [class.input-red]="commandFormControl.value == undefined"
                      placeholder="New command" [formControl]="commandFormControl" type="text">
                    <button type="button" (click)="onAddElement('spec.command')" class="btn-add-cmd">
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                  <div class="mt-1">
                    <span *ngFor="let item of getFormArray('spec.command').controls; let i=index;"
                      class="badge badge-secondary mr-2 mb-1">{{ item.value }}
                      <i class="fa fa-times" (click)="onRemoveElement('spec.command', i)"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Args Section -->
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Args <small>Fill to input before clic on button <i class="fa fa-plus"></i></small></label>
                  <div class="d-flex">
                    <input class="form-control" [class.input-red]="argsFormControl.value == undefined"
                      placeholder="New arg" [formControl]="argsFormControl" type="text"><button type="button"
                      (click)="onAddElement('spec.args')" class="btn-add-cmd"><i class="fa fa-plus"></i></button>
                  </div>
                  <div class="mt-1">
                    <span *ngFor="let item of getFormArray('spec.args').controls; let i=index;"
                      class="badge badge-secondary mr-2 mb-1">{{ item.value }} <i (click)="onRemoveElement('spec.args', i)"
                        class="fa fa-times"></i></span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Lifecycle Section -->
            <div class="row">
              <div class="col-md-12">
                <div class="form-group" formGroupName="lifecycle">
                  <label>Lifecycle - Post Start Commands </label>
                  <div formGroupName="postStart">
                    <textarea class="form-control mb-3 p-2" placeholder="Put your commands here"
                      formControlName="command" rows="2"></textarea>
                    <br>
                    <small>Separate commands with a semicolon <strong>;</strong>. Example: php artisan
                      migrate; php
                      artisan
                      route:cache;</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- pills-containerSecurityContext -->
          <div class="tab-pane fade p-3" formGroupName="spec" id="v-pills-8" [class.show]="step == 8" [class.active]="step == 8"
            role="tabpanel" aria-labelledby="v-pills-tab-8">
            <div class="row">
              <div class="col-5">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical"
                  style="background: white;">
                  <button *ngFor="let csck of containerSecurityContextKeys; let k=index" class="nav-link"
                    [class.active]="k==0" [id]="'v-pills-'+csck.name+'-tab'" data-toggle="pill"
                    [attr.data-target]="'#v-pills-'+csck.name" type="button" role="tab"
                    [attr.aria-controls]="'v-pills-'+csck.name" aria-selected="true">
                    {{ csck.label }} <i class="fa fa-caret-right"></i>
                  </button>
                </div>
              </div>
              <div class="col-7">
                <div class="tab-content" id="v-pills-tabContent" formGroupName="securityContext">
                  <div *ngFor="let csck of containerSecurityContextKeys; let k=index" class="tab-pane fade row"
                    [class.active]="k==0" [class.show]="k==0" [id]="'v-pills-'+csck.name" role="tabpanel"
                    [attr.aria-labelledby]="'v-pills-'+csck.name+'-tab'">

                    <!-- allowPrivilegeEscalation -->
                    <div class="col-md-12 form-group" *ngIf="csck.name == 'others'">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="containerAllowPrivilegeEscalation"
                          formControlName="allowPrivilegeEscalation">
                        <label class="custom-control-label pt-1" for="containerAllowPrivilegeEscalation">
                          Allow Privilege Escalation
                        </label>
                      </div>
                    </div>

                    <!-- privileged -->
                    <div class="col-md-12 form-group" *ngIf="csck.name == 'others'">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="containerPrivileged"
                          formControlName="privileged">
                        <label class="custom-control-label pt-1" for="containerPrivileged">
                          Privileged
                        </label>
                      </div>
                    </div>

                    <!-- readOnlyRootFilesystem -->
                    <div class="col-md-12 form-group" *ngIf="csck.name == 'others'">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="containerReadOnlyRootFilesystem"
                          formControlName="readOnlyRootFilesystem">
                        <label class="custom-control-label pt-1" for="containerReadOnlyRootFilesystem">
                          Read Only Root Filesystem
                        </label>
                      </div>
                    </div>

                    <!-- runAsNonRoot -->
                    <div class="col-md-12 form-group" *ngIf="csck.name == 'others'">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="containerRunAsNonRoot"
                          formControlName="runAsNonRoot">
                        <label class="custom-control-label pt-1" for="containerRunAsNonRoot">
                          Run As Non Root
                        </label>
                      </div>
                    </div>

                    <!-- procMount -->
                    <div class="col-md-12" *ngIf="csck.name == 'others'">
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label>Proc Mount</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="procMount" type="text">
                        </div>
                      </div>
                    </div>

                    <!-- runAsGroup -->
                    <div class="col-md-12" *ngIf="csck.name == 'others'">
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label>Run As Group</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="runAsGroup" type="number">
                        </div>
                      </div>
                    </div>

                    <!-- runAsUser -->
                    <div class="col-md-12" *ngIf="csck.name == 'others'">
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label>Run As User</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="runAsUser" type="number">
                        </div>
                      </div>
                    </div>

                    <!-- seccompProfile -->
                    <div class="col-md-12" formGroupName="seccompProfile" *ngIf="csck.name == 'seccompProfile'">
                      <!-- type -->
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label for="seccompTypeContainer">Type</label>
                        </div>
                        <div class="col-md-8">
                          <input id="seccompTypeContainer" class="form-control" formControlName="type" type="text">
                        </div>
                      </div>

                      <!-- localhostProfile -->
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label for="seccompLocalhostProfileContainer">Localhost Profile</label>
                        </div>
                        <div class="col-md-8">
                          <input id="seccompLocalhostProfileContainer" class="form-control"
                            formControlName="localhostProfile" type="text">
                        </div>
                      </div>
                    </div>

                    <!-- seLinuxOptions -->
                    <div class="col-md-12" formGroupName="seLinuxOptions" *ngIf="csck.name == 'seLinuxOptions'">
                      <!-- user -->
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label for="seUserContainer">User</label>
                        </div>
                        <div class="col-md-8">
                          <input id="seUserContainer" class="form-control" formControlName="user" type="text">
                        </div>
                      </div>

                      <!-- role -->
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label for="seRoleContainer">Role</label>
                        </div>
                        <div class="col-md-8">
                          <input id="seRoleContainer" class="form-control" formControlName="role" type="text">
                        </div>
                      </div>

                      <!-- type -->
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label for="seTypeContainer">Type</label>
                        </div>
                        <div class="col-md-8">
                          <input id="seTypeContainer" class="form-control" formControlName="type" type="text">
                        </div>
                      </div>

                      <!-- level -->
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label for="seLevelContainer">Level</label>
                        </div>
                        <div class="col-md-8">
                          <input id="seLevelContainer" class="form-control" formControlName="level" type="text">
                        </div>
                      </div>
                    </div>

                    <!-- windowsOptions -->
                    <div class="col-md-12" formGroupName="windowsOptions" *ngIf="csck.name == 'windowsOptions'">
                      <!-- gmsaCredentialSpecName -->
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label for="gmsaCredentialSpecName">GMSA Credential Spec Name</label>
                        </div>
                        <div class="col-md-8">
                          <input id="gmsaCredentialSpecName" class="form-control"
                            formControlName="gmsaCredentialSpecName" type="text">
                        </div>
                      </div>

                      <!-- runAsUserName -->
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label for="runAsUserName">Run As User Name</label>
                        </div>
                        <div class="col-md-8">
                          <input id="runAsUserName" class="form-control" formControlName="runAsUserName" type="text">
                        </div>
                      </div>
                    </div>

                    <!-- capabilities -->
                    <div class="col-md-12" formGroupName="capabilities" *ngIf="csck.name == 'capabilities'">
                      <!-- add -->
                      <div class="row form-group">
                        <div class="col-md-8">
                          <button type="button" class="btn btn-sm btn-primary mb-2" style="color: white !important;"
                            (click)="onAddFormGroup('spec.securityContext.capabilities.add')">Add
                            Capability</button>
                        </div>
                        <div class="col-md-12" formArrayName="add">
                          <div class="row">
                            <div class="col-md-4 mb-2"
                              *ngFor="let capa of getFormArray('spec.securityContext.capabilities.add').controls; let i = index">
                              <i (click)="onRemoveFormGroup('spec.securityContext.capabilities.add', i)"
                                style="font-size: 18px !important;" class="fa text-danger fa-times custom-icon"></i>
                              <select [formControlName]="i" class="form-control">
                                <option [value]="capatibility" *ngFor="let capatibility of capatibilities">
                                  {{ capatibility }}
                                </option>
                              </select>
                              <div class="text-right d-flex justify-content-between mt-1">
                                <small *ngIf="capa.invalid && (capa.touched || formError)" class="invalid-text">Invalid
                                  field</small>
                                <small *ngIf="capa.hasError('duplicate') && (capa.touched || formError)"
                                  class="invalid-text">Duplicate
                                  key</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- drop -->
                      <div class="row form-group">
                        <div class="col-md-8">
                          <button type="button" class="btn btn-sm btn-primary mb-2" style="color: white !important;"
                            (click)="onAddFormGroup('spec.securityContext.capabilities.drop')">Drop
                            Capability</button>
                        </div>
                        <div class="col-md-12" formArrayName="drop">
                          <div class="row">
                            <div class="col-md-4 mb-2"
                              *ngFor="let capd of getFormArray('spec.securityContext.capabilities.drop').controls; let i = index">
                              <i (click)="onRemoveFormGroup('spec.securityContext.capabilities.drop', i)"
                                style="font-size: 18px !important;" class="fa text-danger fa-times custom-icon"></i>
                              <select [formControlName]="i" class="form-control">
                                <option [value]="capatibility" *ngFor="let capatibility of capatibilities">
                                  {{ capatibility }}
                                </option>
                              </select>
                              <div class="text-right d-flex justify-content-between mt-1">
                                <small *ngIf="capd.invalid && (capd.touched || formError)" class="invalid-text">Invalid
                                  field</small>
                                <small *ngIf="capd.hasError('duplicate') && (capd.touched || formError)"
                                  class="invalid-text">Duplicate
                                  key</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- pills-podSecurityContext -->
          <div class="tab-pane fade p-3" formGroupName="spec" id="v-pills-9" [class.show]="step == 9" [class.active]="step == 9"
            role="tabpanel" aria-labelledby="v-pills-tab-9">

            <div class="row">
              <div class="col-5">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical"
                  style="background: white;">
                  <button *ngFor="let psck of podSecurityContextKeys; let j=index" class="nav-link"
                    [class.active]="j==0" [id]="'v-pills-'+psck.name+'-tab'" data-toggle="pill"
                    [attr.data-target]="'#v-pills-'+psck.name" type="button" role="tab"
                    [attr.aria-controls]="'v-pills-'+psck.name" aria-selected="true">
                    {{ psck.label }} <i class="fa fa-caret-right"></i>
                  </button>
                </div>
              </div>
              <div class="col-7">
                <!-- show active -->
                <div class="tab-content" id="v-pills-tabContent" formGroupName="podSecurityContext">
                  <div *ngFor="let psck of podSecurityContextKeys; let i=index" class="tab-pane fade row"
                    [id]="'v-pills-'+psck.name" role="tabpanel" [attr.aria-labelledby]="'v-pills-'+psck.name+'-tab'"
                    [class.active]="i==0" [class.show]="i==0">

                    <!-- runAsNonRoot -->
                    <div class="col-md-12 form-group" *ngIf="psck.name == 'podOthers'">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="podRunAsNonRoot"
                          formControlName="runAsNonRoot">
                        <label class="custom-control-label pt-1" for="podRunAsNonRoot">
                          Run As Non Root
                        </label>
                      </div>
                    </div>

                    <!-- fsGroup -->
                    <div class="col-md-12" *ngIf="psck.name == 'podOthers'">
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label>FS Group</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="fsGroup" type="number">
                        </div>
                      </div>
                    </div>

                    <!-- runAsGroup -->
                    <div class="col-md-12" *ngIf="psck.name == 'podOthers'">
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label>Run As Group</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="runAsGroup" type="number">
                        </div>
                      </div>
                    </div>


                    <!-- runAsUser -->
                    <div class="col-md-12" *ngIf="psck.name == 'podOthers'">
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label>Run As User</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="runAsUser" type="number">
                        </div>
                      </div>
                    </div>


                    <!-- seccompProfile -->
                    <div class="col-md-12" formGroupName="seccompProfile" *ngIf="psck.name == 'podSeccompProfile'">
                      <!-- type -->
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label for="seccompTypeContainer">Type</label>
                        </div>
                        <div class="col-md-8">
                          <input id="seccompTypeContainer" class="form-control" formControlName="type" type="text">
                        </div>
                      </div>

                      <!-- localhostProfile -->
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label for="seccompLocalhostProfileContainer">Localhost Profile</label>
                        </div>
                        <div class="col-md-8">
                          <input id="seccompLocalhostProfileContainer" class="form-control"
                            formControlName="localhostProfile" type="text">
                        </div>
                      </div>
                    </div>

                    <!-- seLinuxOptions -->
                    <div class="col-md-12" formGroupName="seLinuxOptions" *ngIf="psck.name == 'podSeLinuxOptions'">
                      <!-- user -->
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label>User</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="user" type="text">
                        </div>
                      </div>

                      <!-- role -->
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label>Role</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="role" type="text">
                        </div>
                      </div>

                      <!-- type -->
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label>Type</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="type" type="text">
                        </div>
                      </div>

                      <!-- level -->
                      <div class="row form-group">
                        <div class="col-md-4">
                          <label>Level</label>
                        </div>
                        <div class="col-md-8">
                          <input class="form-control" formControlName="level" type="text">
                        </div>
                      </div>
                    </div>

                    <!-- supplementalGroups -->
                    <div class="col-md-12" *ngIf="psck.name == 'podSupplementalGroups'">
                      <div class="row form-group">
                        <div class="col-md-8">
                          <button type="button" class="btn btn-sm btn-primary mb-2" style="color: white !important;"
                            (click)="onAddFormGroup('spec.podSecurityContext.supplementalGroups')">Add
                            Group</button>
                        </div>
                        <div class="col-md-12" formArrayName="supplementalGroups">
                          <div class="row">
                            <div class="col-md-4 mb-1"
                              *ngFor="let group of getFormArray('spec.podSecurityContext.supplementalGroups').controls; let i = index">
                              <i (click)="onRemoveFormGroup('spec.podSecurityContext.supplementalGroups', i)"
                                style="font-size: 18px !important;" class="fa text-danger fa-times custom-icon"></i>
                              <input [formControlName]="i" class="form-control" type="number">
                              <div class="text-right d-flex justify-content-between mt-1">
                                <small *ngIf="group.invalid && (group.touched || formError)"
                                  class="invalid-text">Invalid
                                  field</small>
                                <small *ngIf="group.hasError('duplicate') && (group.touched || formError)"
                                  class="invalid-text">Duplicate
                                  key</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <!-- sysctls -->
                    <div class="col-md-12" *ngIf="psck.name == 'podSysctls'">
                      <div class="row form-group">
                        <div class="col-md-8">
                          <button type="button" class="btn btn-sm btn-primary mb-2" style="color: white !important;"
                            (click)="onAddFormGroup('spec.podSecurityContext.sysctls')">Add Sysctl</button>
                        </div>
                        <div class="col-md-12">
                          <div formArrayName="sysctls">
                            <div class="row mb-1"
                              *ngFor="let sysctl of getFormArray('spec.podSecurityContext.sysctls').controls; let i = index"
                              [formGroupName]="i">
                              <div class="col-md-12">
                                <i (click)="onRemoveFormGroup('spec.podSecurityContext.sysctls', i)"
                                  style="font-size: 18px !important;" class="fa text-danger fa-times custom-icon"></i>
                              </div>
                              <div class="col-md-6">
                                <div class="row form-group">
                                  <div class="col-md-4">
                                    <label for="sysctlName">Name</label>
                                  </div>
                                  <div class="col-md-8">
                                    <input id="sysctlName" class="form-control" formControlName="name" type="text">
                                    <div class="text-right d-flex justify-content-between mt-1">
                                      <small
                                        *ngIf="sysctl.controls.name.invalid && (sysctl.controls.name.touched || formError)"
                                        class="invalid-text">Invalid
                                        field</small>
                                      <small
                                        *ngIf="sysctl.controls.name.hasError('duplicate') && (sysctl.controls.name.touched || formError)"
                                        class="invalid-text">Duplicate key</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="row form-group">
                                  <div class="col-md-4">
                                    <label for="sysctlValue">Value</label>
                                  </div>
                                  <div class="col-md-8">
                                    <input id="sysctlValue" class="form-control" formControlName="value" type="text">
                                    <div class="text-right d-flex justify-content-between mt-1">
                                      <small
                                        *ngIf="sysctl.controls.value.invalid && (sysctl.controls.value.touched || formError)"
                                        class="invalid-text">Invalid
                                        field</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- pills-nodeSelector -->
          <div class="tab-pane fade p-3" formGroupName="spec" id="v-pills-10" [class.show]="step == 10" [class.active]="step == 10"
            role="tabpanel" aria-labelledby="v-pills-tab-10">
            <label>Node Selector</label>
            <div formArrayName="nodeSelector">
              <div class="mt-3" *ngFor="let nodeSelector of getFormArray('spec.nodeSelector').controls; let i = index"
                [formGroupName]="i">
                <div class="row custom-fieldset">
                  <div class="col-md-6 col-lg-6 form-group">
                    <input class="form-control" formControlName="key" placeholder="Key" />
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="nodeSelector.controls.key.invalid && (nodeSelector.controls.key.touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                  <div class="col-md-5 col-lg-5 form-group">
                    <input class="form-control" formControlName="value" placeholder="Value" />
                    <div class="text-right d-flex justify-content-between mt-1"
                      *ngIf="nodeSelector.controls.value.invalid && (nodeSelector.controls.value.touched || formError)">
                      <small class="invalid-text">Invalid field</small>
                    </div>
                  </div>
                  <div class="col-lg-1 col-md-1" style="display: flex; justify-content: center;">
                    <button (click)="onRemoveFormGroup('spec.nodeSelector', i)" class="btn_delete">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12" *ngIf="deploymentForm.get('spec.nodeSelector')?.controls.length > 0">
                  <button type="button" [disabled]="deploymentForm.get('spec.nodeSelector').invalid"
                    (click)="onAddFormGroup('spec.nodeSelector')" class="add-button">
                    <i class="fa fa-plus"></i>&nbsp; Node Selector
                  </button>
                </div>
                <div class="col-md-12 col-xl-12" *ngIf="deploymentForm.get('spec.nodeSelector')?.controls.length == 0">
                  <div class="row"
                    style="width: inherit;display: flex; flex-direction: row; align-items: center;justify-content: center;height: fit-content;">
                    <button type="button" [disabled]="deploymentForm.get('spec.nodeSelector').invalid"
                      (click)="onAddFormGroup('spec.nodeSelector')" class="add-button"
                      style="margin-left: 0px !important; width: 11vw;">
                      <i class="fa fa-plus"></i>&nbsp; Node Selector
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- pills-tolerations -->
          <div class="tab-pane fade p-3" formGroupName="spec" id="v-pills-11" [class.show]="step == 11" [class.active]="step == 11"
            role="tabpanel" aria-labelledby="v-pills-tab-11">
            <label>Tolerations</label>

            <div formArrayName="tolerations">
              <div class="mt-3" *ngFor="let toleration of getFormArray('spec.tolerations').controls; let i = index"
                [formGroupName]="i">

                <div class="row custom-fieldset" style="align-items: center !important;">
                  <div class="col-lg-12 col-md-12">
                    <hr style="color: #517abdba; height: 1px; width: 200px;margin-left: 45%;" *ngIf="i > 0" />
                  </div>
                  <div class="col-md-6 col-xl-6">
                    <div class="form-group">
                      <input class="form-control" formControlName="key" placeholder="Key" />
                      <div class="text-right d-flex justify-content-between mt-1"
                        *ngIf="toleration.controls.key.invalid && (toleration.controls.key.touched || formError)">
                        <small class="invalid-text">Invalid field</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-6">
                    <div class="form-group">
                      <input class="form-control" formControlName="value" placeholder="Value" />
                      <div class="text-right d-flex justify-content-between mt-1"
                        *ngIf="toleration.controls.value.invalid && (toleration.controls.value.touched || formError)">
                        <small class="invalid-text">Invalid field</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-6">
                    <label for="">Operator</label>
                    <div class="form-group">
                      <select class="form-control" formControlName="operator">
                        <option *ngFor="let tolerationOperator of tolerationOperators"
                          [value]="tolerationOperator.value">
                          {{ tolerationOperator.label }}
                        </option>
                      </select>
                      <div class="text-right d-flex justify-content-between mt-1"
                        *ngIf="toleration.controls.operator.invalid && (toleration.controls.operator.touched || formError)">
                        <small class="invalid-text">Invalid field</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 col-xl-5">
                    <label for="">Effect</label>
                    <div class="form-group">
                      <select class="form-control" formControlName="effect">
                        <option *ngFor="let tolerationEffet of tolerationEffets" [value]="tolerationEffet.value">
                          {{ tolerationEffet.label }}
                        </option>
                      </select>
                      <div class="text-right d-flex justify-content-between mt-1"
                        *ngIf="toleration.controls.effect.invalid && (toleration.controls.effect.touched || formError)">
                        <small class="invalid-text">Invalid field</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-1 col-md-1" style="display: flex; justify-content: center;">
                    <button (click)="onRemoveFormGroup('spec.tolerations', i)" class="btn_delete">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12" *ngIf="getFormArray('spec.tolerations').controls?.length > 0">
                  <button type="button" [disabled]="deploymentForm.get('spec.tolerations').invalid"
                    (click)="onAddFormGroup('spec.tolerations')" class="add-button">
                    <i class="fa fa-plus"></i> &nbsp;Toleration
                  </button>
                </div>
                <div class="col-md-12 col-xl-12" *ngIf="getFormArray('spec.tolerations').controls?.length == 0">
                  <div class="row"
                    style="width: inherit;display: flex; flex-direction: row; align-items: center;justify-content: center;height: fit-content;">
                    <button type="button" [disabled]="deploymentForm.get('spec.tolerations').invalid"
                      (click)="onAddFormGroup('spec.tolerations')" class="add-button"
                      style="margin-left: 0px !important; width: 11vw;">
                      <i class="fa fa-plus"></i> &nbsp;Toleration
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3"></div>
        <div class="col-9 text-center mt-4">
          <button class="btn-cancel mr-2 nowrap" [routerLink]="[ '/deploy']">
            Cancel
          </button>
          <button class="create-deploy mr-2 nowrap" (click)="onSubmit()" [disabled]="formSubmitted">
            {{ deployment?.id && !duplicate ? "Update Deployment" : "Create Deployment" }}
            <i class="fa fa-spin fa-spinner" *ngIf="formSubmitted"></i>
          </button>
          <button class="btn-move bg-dark mr-2" [disabled]="invalidStep(step)" type="button" *ngIf="step > 1"
            (click)="step = step-1">
            <i class="fa fa-chevron-left"></i>
          </button>
          <button class="btn-move bg-dark" type="button" [disabled]="invalidStep(step)" *ngIf="step < 11"
            (click)="step = step+1">
            <i class="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</section>
