import { Component, TemplateRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {  Position, Type } from 'src/app/utils/const';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { ShareService } from 'src/app/data/service/share.service';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FormModalComponent } from './form-modal/form-modal.component';
import { GroupService } from '../service/group.service';
import { Group } from 'src/app/data/model/group';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class GroupComponent {
  groups = [];
  page: number = 1;
  limit = 10;
  total = 0;
  pagination: any;
  modalRef: BsModalRef;
  groupSelected: any;
  groupLoaded = false;
  groupNameControl: UntypedFormControl;
  disabledBtn = true;

  constructor(
    private groupService: GroupService,
    public auth: AppAuthGuardService,
    private service: AsideExtenderService,
    private modalService: BsModalService,
    public shareService: ShareService
  ) {}

  async ngOnInit(): Promise<void> {
    this.getGroups(this.page, this.limit);
  }

  getGroups(page: number, limit: number) {
    this.total = 0;
    this.groupLoaded = true;
    this.groupService.getAll(page, limit).subscribe(
      (response: any) => {
        this.groupLoaded = false;
        this.groups = response.records;
        this.page = response.page;
        this.total = response.totalRecord;
      },
      (error) => {
        this.groupLoaded = false;
        this.groups = [];
      }
    );
  }

  onOpenDeleteModal(template: TemplateRef<any>, group: any) {
    this.groupSelected = group;
    this.groupNameControl = new UntypedFormControl('', [Validators.required]);
    const config: ModalOptions = {
      class: 'modal-md modal-dialog-centered',
      backdrop: 'static',
    };
    this.modalRef = this.modalService.show(template, config);
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  pageChanged(event: any) {
    this.getGroups(event.page, this.limit);
  }

  onSubmitDeleteGroup() {
    this.groupService.delete(this.groupSelected.id).subscribe(
      (_: any) => {
        this.onCloseDialog();
        this.getGroups(this.page, this.limit);
        this.service.show({
          type: Type.SUCCESS,
          message: 'The group has been deleted successfully',
          position: Position.TOP,
          title: 'Group',
        });
      },
      (err) => {
        this.service.show({
          type: Type.ERROR,
          message: err?.error,
          position: Position.TOP,
          title: 'Group',
        });
      }
    );
  }

  onUpGroupName(name: any): void {
    if (this.groupSelected.name === name) {
      this.disabledBtn = false;
    } else {
      this.disabledBtn = true;
    }
  }

  onOpenFormModalGroup(group = null): void {
    const config: any = {
      class: 'popup-right-side-mini',
      initialState: {
        group: group ? group : new Group(),
      },
    };

    this.modalRef = this.modalService.show(FormModalComponent, config);

    this.modalRef.content.event.subscribe((res) => {
      this.getGroups(this.page, this.limit);
    });
  }
}
