<div class="row custom-tabs mt-3">
  <div class="col-md-3 title" (click)="switchTab('organization')" [class.active]="currentTab == 'organization'">
    Organizations
  </div>
  <div class="col-md-3 title" (click)="switchTab('group')" [class.active]="currentTab == 'group'">
    Groups
  </div>
  <div class="col-md-12 content">
    <div class="g-0 row" *ngIf="currentTab == 'organization'">
      <app-organization></app-organization>
    </div>
    <div class="row" *ngIf="currentTab == 'group'">
      <app-group></app-group>
    </div>
  </div>
</div>
