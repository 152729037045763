import { Component, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { App, Metadata } from 'src/app/data/model/apps';
import {
  Deployment,
  DeploymentSpecs,
  Env,
  Ingress,
} from 'src/app/data/model/deployment';
import { Environment } from 'src/app/data/model/environment';
import { Project } from 'src/app/data/model/project';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { AppService } from 'src/app/modules/app/app.service';
import { DeploymentService } from 'src/app/modules/deploy/services/deployment.service';
import { ProjectService } from 'src/app/modules/onboard/services/project.service';
import {
  Type,
  Position,
  generateRandomString,
  ACCESS_MODES,
  TOLERATION_OPERATORS,
  TOLERATION_EFFETS,
  CAPABILITIES,
} from 'src/app/utils/const';
import { Domain, Registry } from 'src/app/utils/types';
import { cpuUnits, memoryUnits } from 'src/app/utils/unit';

@Component({
  selector: 'app-form-deployment',
  templateUrl: './form-deployment.component.html',
  styleUrls: ['./form-deployment.component.scss'],
})
export class FormDeploymentComponent implements OnInit {
  deploymentForm: FormGroup;

  @Input() deployment: Deployment;

  @Output() change: Deployment;

  projects: Project[] = [];
  loaderProject: boolean = false;
  currentFilterProject: string = '';

  environments: Environment[] = [];
  loaderEnvironment: boolean = false;
  currentFilterEnvironment: string = '';

  advanced = false;
  loading: boolean = false;
  formError = false;
  formSubmitted = false;

  steps = [
    { label: 'General', value: 1, disabled: true },
    { label: 'Exposition', value: 2, disabled: true },
    { label: 'Environments Variables', value: 3, disabled: true },
    { label: 'HPA/Resources', value: 4, disabled: true },
    { label: 'Volumes', value: 5, disabled: true },

    { label: 'Probes', value: 6, disabled: true }, // 8 => 6
    { label: 'Args/Commands/Lifecycle', value: 7, disabled: true }, // 9 => 7
    { label: 'Container Security Context', value: 8, disabled: true }, // 10 => 8
    { label: 'Pod Security Context', value: 9, disabled: true }, // 11 => 9

    { label: 'Nodes Selectors', value: 10, disabled: true }, // 6 => 10
    { label: 'Tolerations', value: 11, disabled: true }, // 7 => 11
  ];

  step = 1;

  loaderRegistry: boolean = false;
  registries: Registry[] = [];
  tempRegistries: Registry[] = [];

  domains: Domain[] = [];
  tempDomains: Domain[] = [];
  loaderDomain: boolean = false;
  currentFilterDomain: string = '';
  randomElement: string = '';
  domainsShared = false;

  cpuUnits = cpuUnits;
  memoryUnits = memoryUnits;
  accessModes = ACCESS_MODES;
  storageUnits = [];
  resourceUnits = [];

  tolerationOperators = TOLERATION_OPERATORS;
  tolerationEffets = TOLERATION_EFFETS;

  randomStr = '';

  labelK8sRegex =
    '^([a-z0-9]([-a-z0-9]*[a-z0-9])?.)*[a-z0-9]([-a-z0-9]*[a-z0-9])?/[a-z0-9A-Z]([-a-z0-9A-Z_.]*[a-z0-9A-Z])?$|^[a-z0-9A-Z]([-a-z0-9A-Z_.]*[a-z0-9A-Z])?$';
  nameK8sRegex = '^[a-z0-9]([-a-z0-9]*[a-z0-9])?$';
  pathK8sRegex = '^/([a-zA-Z0-9._-]+(/[a-zA-Z0-9._-]+)*)?/?$';
  imageK8sRegex =
    '^(?:[a-z0-9]+(?:[._-][a-z0-9]+)*(?::[0-9]+)?/)?[a-z0-9]+(?:[._-][a-z0-9]+)*(?:/[a-z0-9]+(?:[._-][a-z0-9]+)*)*(?::[a-zA-Z0-9._-]+)?(?:@[a-fA-F0-9]{64})?$';
  // Independant properties
  imageTagFormControl: FormControl<any>;

  appExpositionControl: FormControl;

  requestCpuFormControl: FormControl;
  requestMemoryFormControl: FormControl;
  limitCpuFormControl: FormControl;
  limitMemoryFormControl: FormControl;

  commandFormControl: FormControl;
  argsFormControl: FormControl;

  livenessProbeHeadersNameFormControl: FormControl;
  livenessProbeHeadersValueFormControl: FormControl;
  readinessProbeHeadersNameFormControl: FormControl;
  readinessProbeHeadersValueFormControl: FormControl;
  livenessExecCommandFormControl: FormControl;
  readinessExecCommandFormControl: FormControl;

  livenessTypeFormControl: FormControl;
  readinessTypeFormControl: FormControl;

  livenessAdvance = false;
  readinessAdvance = false;

  resourcesTypeFormControl: FormControl;

  duplicate = false;

  probesTypes = [
    { label: 'HTTP Get', value: 'httpGet' },
    { label: 'Exec', value: 'exec' },
    { label: 'TCP Socket', value: 'tcpSocket' },
  ];

  capatibilities = CAPABILITIES;
  containerSecurityContextKeys = [
    {
      label: 'Capabilities',
      name: 'capabilities',
      selected: false,
    },
    {
      label: 'SE Linux Options',
      name: 'seLinuxOptions',
      selected: false,
    },
    {
      label: 'Seccomp Profile',
      name: 'seccompProfile',
      selected: false,
    },
    {
      label: 'Windows Options',
      name: 'windowsOptions',
      selected: false,
    },
    {
      label: 'Others',
      name: 'others',
      selected: false,
    },
  ];

  podSecurityContextKeys = [
    {
      label: 'SE Linux Options',
      name: 'podSeLinuxOptions',
      selected: false,
    },
    {
      label: 'Supplemental Groups',
      name: 'podSupplementalGroups',
      selected: false,
    },
    {
      label: 'Sysctls',
      name: 'podSysctls',
      selected: false,
    },
    {
      label: 'Seccomp Profile',
      name: 'podSeccompProfile',
      selected: false,
    },
    {
      label: 'Others',
      name: 'podOthers',
      selected: false,
    },
  ];

  publicRegistries = [
    {
      label: 'Docker',
      uri: 'index.docker.io',
    },
    {
      label: 'GitHub Container Registry (GHCR)',
      uri: 'ghcr.io',
    },
    {
      label: 'Google Container Registry (GCR)',
      uri: 'gcr.io',
    },
    {
      label: 'Amazon Elastic Container Registry (ECR)',
      uri: 'public.ecr.aws',
    },
    {
      label: 'Microsoft Azure Container Registry (ACR)',
      uri: 'mcr.microsoft.com',
    },
    {
      label: 'Quay.io',
      uri: 'quay.io',
    },
  ];

  resourcesTypes = [
    { label: 'CPU Resources', value: 'cpu' },
    { label: 'GPU Resources', value: 'gpu' },
  ];

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private router: Router,
    private appService: AppService,
    private deploymentService: DeploymentService,
    private service: AsideExtenderService,
    private route: ActivatedRoute
  ) {
    this.createForm();
  }

  async ngOnInit() {
    this.randomStr = this.genStr(6);
    this.loading = true;

    let id = null;
    let projectId = null;
    let environmentId = null;

    this.route.params.subscribe((param: any) => {
      id = param.id;
    });
    this.route.queryParams.subscribe((param: any) => {
      projectId = param.projectId;
      environmentId = param.environmentId;
      this.duplicate = param.duplicate;
      if (this.duplicate) {
        id = param.id;
      }
    });

    this.loaderProject = true;
    await this.getProjects();

    if (id) {
      await this.getDeployment(projectId, environmentId, id, this.duplicate);
    }
    await this.getAllRegistries();

    this.selectProject();

    this.loaderProject = false;
    this.loading = false;

    this.deploymentForm
      .get('usePublicRegistry')
      .valueChanges.subscribe((value) => {
        if (value) {
          this.deploymentForm
            .get('registryId')
            .removeValidators([Validators.required]);
          this.deploymentForm.get('registryId').setValue('');
        } else {
          this.deploymentForm
            .get('registryId')
            .addValidators([Validators.required]);
          this.selectRegistry();
        }
        this.deploymentForm.get('registryId').updateValueAndValidity();
      });
  }

  getDeployment(projectId, environmentId, id, duplicate = false) {
    return new Promise((resolve, reject) => {
      this.deploymentService
        .getDeployment(projectId, environmentId, id)
        .subscribe(
          async (response: Deployment) => {
            this.deployment = response;

            if (duplicate) {
              const newName = `${this.deployment.spec.nameOverride}-copy`;
              this.deployment.spec.nameOverride = newName;
              this.deploymentForm.get('spec.nameOverride').enable();
              this.onChangeName({ target: { value: newName } });
            } else {
              this.imageTagFormControl.disable();
              this.deploymentForm.get('spec.nameOverride').disable();
            }

            await this.appService
              .getDomainsByEnv(environmentId)
              .toPromise()
              .then((response: Domain[]) => {
                this.domains = response;

                let formatIngress: any = this.deployment.spec.ingress;

                if (!Array.isArray(formatIngress)) {
                  const domain: any = this.domains.find(
                    (d) => d.id == this.deployment.domainID
                  );

                  formatIngress.domainId = this.deployment.domainID;
                  formatIngress.domain = domain?.host;
                  formatIngress.domainShared = domain?.shared;

                  const subdomain = formatIngress.host.replace(
                    '.' + domain?.host,
                    ''
                  );
                  formatIngress.subdomain = subdomain;
                  formatIngress = [formatIngress];
                  this.deployment.spec.ingress = formatIngress;
                }

                this.domainsShared = true;
                response.forEach((domain) => {
                  if (!domain.shared) {
                    this.domainsShared = false;
                  }
                });
                this.tempDomains = response;
              });

            this.setForm();
            resolve(response);
          },
          (error: any) => {
            this.service.show({
              title: 'Deploy',
              position: Position.TOP,
              type: Type.ERROR,
              message: 'Project or Environment nor found',
            });
            this.router.navigate(['/deploy']);
            reject(reject);
          }
        );
    });
  }

  deleteIngressesField(){
    for (let index = 0; index < this.getFormArray('spec.ingress').controls.length; index++) {
      this.onRemoveFormGroup('spec.ingress', index);
    }
    if (this.getFormArray('spec.ingress').controls.length > 0) {
      this.deleteIngressesField();
    }
  }

  createForm() {
    this.imageTagFormControl = this.fb.control('', [
      Validators.required,
      Validators.pattern(this.imageK8sRegex),
    ]);

    this.appExpositionControl = this.fb.control(false);
    this.appExpositionControl.valueChanges.subscribe((expose) => {
        if (expose) {
          if (this.deployment?.id) {
            const ingresses = this.deployment?.spec?.ingress;
            this.fillIngressesFields(ingresses);
          }else{
            this.onAddFormGroup('spec.ingress');
            this.onChangeName({
              target: {
                value: this.deploymentForm.get('spec.nameOverride').value,
              },
            });
            this.selectDomain();
          }
        } else {
          this.deleteIngressesField();
        }
    });

    this.imageTagFormControl.valueChanges.subscribe((value) => {
      this.splitImageTag(value);
    });

    this.requestCpuFormControl = this.fb.control('m', [Validators.required]);
    this.requestMemoryFormControl = this.fb.control('Mi', [
      Validators.required,
    ]);
    this.limitCpuFormControl = this.fb.control('m', [Validators.required]);
    this.limitMemoryFormControl = this.fb.control('Mi', [Validators.required]);

    this.commandFormControl = this.fb.control('');
    this.argsFormControl = this.fb.control('');

    this.livenessProbeHeadersNameFormControl = this.fb.control('');
    this.livenessProbeHeadersValueFormControl = this.fb.control('');
    this.readinessProbeHeadersNameFormControl = this.fb.control('');
    this.readinessProbeHeadersValueFormControl = this.fb.control('');
    this.readinessExecCommandFormControl = this.fb.control('');
    this.livenessExecCommandFormControl = this.fb.control('');

    this.livenessTypeFormControl = this.fb.control('');
    this.readinessTypeFormControl = this.fb.control('');

    this.livenessTypeFormControl.valueChanges.subscribe((value) => {
      if (this.deploymentForm.get('spec.probes.livenessProbe.enabled').value) {
        if (['httpGet'].includes(value)) {
          this.addRequiredFields('spec.probes.livenessProbe.httpGet', true);

          this.addRequiredFields('spec.probes.livenessProbe.exec', false);
          this.addRequiredFields('spec.probes.livenessProbe.tcpSocket', false);

          this.clearField('spec.probes.livenessProbe.exec');
          this.clearField('spec.probes.livenessProbe.tcpSocket');
        }

        if (['exec'].includes(value)) {
          this.addRequiredFields('spec.probes.livenessProbe.exec', true);

          this.addRequiredFields('spec.probes.livenessProbe.httpGet', false);
          this.addRequiredFields('spec.probes.livenessProbe.tcpSocket', false);

          this.clearField('spec.probes.livenessProbe.httpGet');
          this.clearField('spec.probes.livenessProbe.tcpSocket');
        }

        if (['tcpSocket'].includes(value)) {
          this.addRequiredFields('spec.probes.livenessProbe.tcpSocket', true);

          this.addRequiredFields('spec.probes.livenessProbe.httpGet', false);
          this.addRequiredFields('spec.probes.livenessProbe.exec', false);

          this.clearField('spec.probes.livenessProbe.httpGet');
          this.clearField('spec.probes.livenessProbe.exec');
        }
      }
    });

    this.readinessTypeFormControl.valueChanges.subscribe((value) => {
      if (this.deploymentForm.get('spec.probes.readinessProbe.enabled').value) {
        if (['httpGet'].includes(value)) {
          this.addRequiredFields('spec.probes.readinessProbe.httpGet', true);

          this.addRequiredFields('spec.probes.readinessProbe.exec', false);
          this.addRequiredFields('spec.probes.readinessProbe.tcpSocket', false);

          this.clearField('spec.probes.readinessProbe.exec');
          this.clearField('spec.probes.readinessProbe.tcpSocket');
        }

        if (['exec'].includes(value)) {
          this.addRequiredFields('spec.probes.readinessProbe.exec', true);

          this.addRequiredFields('spec.probes.readinessProbe.httpGet', false);
          this.addRequiredFields('spec.probes.readinessProbe.tcpSocket', false);

          this.clearField('spec.probes.readinessProbe.httpGet');
          this.clearField('spec.probes.readinessProbe.tcpSocket');
        }

        if (['tcpSocket'].includes(value)) {
          this.addRequiredFields('spec.probes.readinessProbe.tcpSocket', true);

          this.addRequiredFields('spec.probes.readinessProbe.httpGet', false);
          this.addRequiredFields('spec.probes.readinessProbe.exec', false);

          this.clearField('spec.probes.readinessProbe.httpGet');
          this.clearField('spec.probes.readinessProbe.exec');
        }
      }
    });

    this.resourcesTypeFormControl = this.fb.control('cpu');

    this.resourcesTypeFormControl.valueChanges.subscribe((value) => {
      if (value == 'gpu') {
        this.deploymentForm.get('spec.resources.limits.gpu').disable();
        this.addRequiredFields('gpu', true);

        this.addRequiredFields('cpu', false);
        this.clearField('cpu');
      }

      if (value == 'cpu') {
        if (this.deploymentForm.get('spec.autoscaling.enabled').value) {
          this.addRequiredFields('cpu', true);
        } else {
          this.addRequiredFields('cpu', false);
        }

        this.addRequiredFields('gpu', false);
        this.clearField('gpu');
      }
    });

    this.deploymentForm = this.fb.group({
      metadata: this.fb.group({
        name: this.fb.control(''),
        namespace: this.fb.control(''),
      }),

      projectId: this.fb.control('', [Validators.required]),
      environmentId: this.fb.control('', [Validators.required]),
      usePublicRegistry: this.fb.control(false),
      registryId: this.fb.control(''),

      spec: this.fb.group({
        nameOverride: this.fb.control('', [
          Validators.required,
          Validators.pattern('^[a-z0-9-]+(?<![-.])$'),
        ]),
        replicaCount: this.fb.control(1),
        port: this.fb.control('', [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
        ]),

        image: this.fb.group({
          repository: this.fb.control(''),
          tag: this.fb.control(''),
        }),

        ingress: this.fb.array([]),

        volumes: this.fb.group({
          emptyDirs: this.fb.array([], this.noDuplicateKeys('name')),
          configMaps: this.fb.array([], this.noDuplicateKeys('name')),
          secrets: this.fb.array([], this.noDuplicateKeys('name')),
          pvcs: this.fb.array([], this.noDuplicateKeys('name')),
        }),
        env: this.fb.array([], this.noDuplicateKeys('key')),
        envFrom: this.fb.group({
          secrets: this.fb.array([], this.noDuplicateKeys('name')),
          configMaps: this.fb.array([], this.noDuplicateKeys('name')),
        }),
        lifecycle: this.fb.group({
          postStart: this.fb.group({
            command: this.fb.control('', [
              Validators.pattern('^([^;]+)(;[^;]+)*;?$'),
            ]),
          }),
        }),
        command: this.fb.array([]),
        args: this.fb.array([]),
        probes: this.fb.group({
          enabled: this.fb.control(false),
          livenessProbe: this.fb.group({
            enabled: this.fb.control(false),
            initialDelaySeconds: this.fb.control(5),
            periodSeconds: this.fb.control(10),
            httpGet: this.fb.group({
              path: this.fb.control('/healthz'),
              port: this.fb.control(null),
              httpHeaders: this.fb.array([], this.noDuplicateKeys('name')),
            }),
            tcpSocket: this.fb.group({
              port: this.fb.control(null),
            }),
            exec: this.fb.group({
              command: this.fb.array([]),
            }),
            timeoutSeconds: this.fb.control(null),
            successThreshold: this.fb.control(null),
            failureThreshold: this.fb.control(null),
          }),
          readinessProbe: this.fb.group({
            enabled: this.fb.control(false),
            initialDelaySeconds: this.fb.control(10),
            periodSeconds: this.fb.control(5),
            httpGet: this.fb.group({
              path: this.fb.control('/healthz'),
              port: this.fb.control(null),
              httpHeaders: this.fb.array([], this.noDuplicateKeys('name')),
            }),
            tcpSocket: this.fb.group({
              port: this.fb.control(null),
            }),
            exec: this.fb.group({
              command: this.fb.array([]),
            }),
            timeoutSeconds: this.fb.control(null),
            successThreshold: this.fb.control(null),
            failureThreshold: this.fb.control(null),
          }),
        }),
        resources: this.fb.group({
          limits: this.fb.group({
            cpu: this.fb.control(''),
            memory: this.fb.control(''),
            // GPU
            gpu: this.fb.control(''),
          }),
          requests: this.fb.group({
            cpu: this.fb.control(''),
            memory: this.fb.control(''),
            // GPU
            gpu: this.fb.control(''),
          }),
        }),
        autoscaling: this.fb.group({
          enabled: this.fb.control(false),
          minReplicas: this.fb.control(1),
          maxReplicas: this.fb.control(5),
          targetCPUUtilizationPercentage: this.fb.control(75),
          targetMemoryUtilizationPercentage: this.fb.control(75),
        }),
        nodeSelector: this.fb.array([]),
        tolerations: this.fb.array([]),

        podSecurityContext: this.fb.group({
          fsGroup: [''],
          runAsGroup: [''],
          runAsNonRoot: [false],
          runAsUser: [''],
          seLinuxOptions: this.fb.group({
            user: [''],
            role: [''],
            type: [''],
            level: [''],
          }),
          supplementalGroups: this.fb.array([]),
          sysctls: this.fb.array([]),
          seccompProfile: this.fb.group({
            type: [''],
            localhostProfile: [''],
          }),
        }),
        securityContext: this.fb.group({
          allowPrivilegeEscalation: [false],
          capabilities: this.fb.group({
            add: this.fb.array([]),
            drop: this.fb.array([]),
          }),
          privileged: [false],
          procMount: [''],
          readOnlyRootFilesystem: [false],
          runAsGroup: [''],
          runAsNonRoot: [false],
          runAsUser: [''],
          seLinuxOptions: this.fb.group({
            user: [''],
            role: [''],
            type: [''],
            level: [''],
          }),
          seccompProfile: this.fb.group({
            type: [''],
            localhostProfile: [''],
          }),
          windowsOptions: this.fb.group({
            gmsaCredentialSpecName: [''],
            runAsUserName: [''],
          }),
        }),
      }),
    });

    this.livenessTypeFormControl.setValue('httpGet');
    this.readinessTypeFormControl.setValue('httpGet');
  }

  changeHost(index: number, subdomain: string, domain: string) {
    if (domain) {
      this.deploymentForm
        .get(`spec.ingress.${index}.host`)
        .setValue(`${subdomain}.${domain}`);
    }
  }

  onChangeResourceRequestGpu(event: any) {
    const value = event.target.value;
    this.deploymentForm.get('spec.resources.limits.gpu').enable();
    this.deploymentForm.get('spec.resources.limits.gpu').setValue(value);
    this.deploymentForm.get('spec.resources.limits.gpu').disable();
  }

  clearField(type: string) {
    switch (type) {
      case 'spec.probes.livenessProbe.exec':
        const liveCommands = this.getFormArray(`${type}.command`).controls;

        for (let index = 0; index < liveCommands.length; index++) {
          this.onRemoveFormGroup(`${type}.command`, index);
        }
        break;
      case 'spec.probes.livenessProbe.httpGet':
        const liveHttpHeaders = this.getFormArray(
          `${type}.httpHeaders`
        ).controls;
        for (let index = 0; index < liveHttpHeaders.length; index++) {
          this.onRemoveFormGroup(`${type}.httpHeaders`, index);
        }

        this.deploymentForm.get(`${type}.port`).setValue(null);
        this.deploymentForm.get(`${type}.path`).setValue('');
        break;
      case 'spec.probes.livenessProbe.tcpSocket':
        this.deploymentForm.get(`${type}.port`).setValue(null);
        break;
      case 'spec.probes.readinessProbe.exec':
        const readiCommands = this.getFormArray(`${type}.command`).controls;

        for (let index = 0; index < readiCommands.length; index++) {
          this.onRemoveFormGroup(`${type}.command`, index);
        }
        break;
      case 'spec.probes.readinessProbe.httpGet':
        const readiHttpHeaders = this.getFormArray(
          `${type}.httpHeaders`
        ).controls;
        for (let index = 0; index < readiHttpHeaders.length; index++) {
          this.onRemoveFormGroup(`${type}.httpHeaders`, index);
        }

        this.deploymentForm.get(`${type}.port`).setValue(null);
        this.deploymentForm.get(`${type}.path`).setValue('');
        break;
      case 'spec.probes.readinessProbe.tcpSocket':
        this.deploymentForm.get(`${type}.port`).setValue(null);
        break;
      case 'cpu':
        this.deploymentForm.get('spec.resources.limits.cpu').setValue(null);
        this.deploymentForm.get('spec.resources.limits.memory').setValue(null);
        this.deploymentForm.get('spec.resources.requests.cpu').setValue(null);
        this.deploymentForm
          .get('spec.resources.requests.memory')
          .setValue(null);
        break;
      case 'gpu':
        this.deploymentForm.get('spec.resources.limits.gpu').setValue(null);
        this.deploymentForm.get('spec.resources.requests.gpu').setValue(null);
        break;
      default:
        break;
    }
  }

  addRequiredFields(type: string, add: boolean) {
    const validators1 = [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.min(1),
    ];

    const validators2 = [
      Validators.required,
      Validators.pattern(this.pathK8sRegex),
    ];

    switch (type) {
      case 'spec.probes.livenessProbe.httpGet':
        if (add) {
          this.addValidators(
            this.deploymentForm.get(`${type}.port`),
            validators1
          );
          this.addValidators(
            this.deploymentForm.get(`${type}.path`),
            validators2
          );
        } else {
          this.removeValidators(
            this.deploymentForm.get(`${type}.port`),
            validators1
          );
          this.removeValidators(
            this.deploymentForm.get(`${type}.path`),
            validators2
          );
        }
        break;
      case 'spec.probes.livenessProbe.exec':
        if (add) {
          this.addValidators(this.deploymentForm.get(`${type}.command`), [
            Validators.required,
          ]);
        } else {
          this.removeValidators(this.deploymentForm.get(`${type}.command`), [
            Validators.required,
          ]);
        }
        break;
      case 'spec.probes.livenessProbe.tcpSocket':
        if (add) {
          this.addValidators(
            this.deploymentForm.get(`${type}.port`),
            validators1
          );
        } else {
          this.removeValidators(
            this.deploymentForm.get(`${type}.port`),
            validators1
          );
        }
        break;
      case 'spec.probes.readinessProbe.httpGet':
        if (add) {
          this.addValidators(
            this.deploymentForm.get(`${type}.port`),
            validators1
          );
          this.addValidators(
            this.deploymentForm.get(`${type}.path`),
            validators2
          );
        } else {
          this.removeValidators(
            this.deploymentForm.get(`${type}.port`),
            validators1
          );
          this.removeValidators(
            this.deploymentForm.get(`${type}.path`),
            validators2
          );
        }
        break;
      case 'spec.probes.readinessProbe.exec':
        if (add) {
          this.addValidators(this.deploymentForm.get(`${type}.command`), [
            Validators.required,
          ]);
        } else {
          this.removeValidators(this.deploymentForm.get(`${type}.command`), [
            Validators.required,
          ]);
        }
        break;
      case 'spec.probes.readinessProbe.tcpSocket':
        if (add) {
          this.addValidators(
            this.deploymentForm.get(`${type}.port`),
            validators1
          );
        } else {
          this.removeValidators(
            this.deploymentForm.get(`${type}.port`),
            validators1
          );
        }
        break;
      case 'cpu':
        const cpuControls = [
          this.deploymentForm.get('spec.resources.limits.cpu'),
          this.deploymentForm.get('spec.resources.limits.memory'),
          this.deploymentForm.get('spec.resources.requests.cpu'),
          this.deploymentForm.get('spec.resources.requests.memory'),
        ];
        if (add) {
          for (let index = 0; index < cpuControls.length; index++) {
            const control = cpuControls[index];
            this.addValidators(control, validators1);
          }
        } else {
          for (let index = 0; index < cpuControls.length; index++) {
            const control = cpuControls[index];
            this.removeValidators(control, validators1);
          }
        }
        break;
      case 'gpu':
        const gpuControls = [
          this.deploymentForm.get('spec.resources.limits.gpu'),
          this.deploymentForm.get('spec.resources.requests.gpu'),
        ];
        if (add) {
          for (let index = 0; index < gpuControls.length; index++) {
            const control = gpuControls[index];
            this.addValidators(control, validators1);
          }
        } else {
          for (let index = 0; index < gpuControls.length; index++) {
            const control = gpuControls[index];
            this.removeValidators(control, validators1);
          }
        }
        break;
      default:
        break;
    }
  }

  onAddElement(type: string) {
    switch (type) {
      case 'spec.command':
        if (this.commandFormControl.value) {
          this.onAddFormGroup(type, this.commandFormControl.value);
          this.commandFormControl.setValue('');
        } else {
          this.commandFormControl.setValue(undefined);
        }
        break;
      case 'spec.args':
        if (this.argsFormControl.value) {
          this.onAddFormGroup(type, this.argsFormControl.value);
          this.argsFormControl.setValue('');
        } else {
          this.argsFormControl.setValue(undefined);
        }
        break;
      case 'spec.probes.livenessProbe.httpGet.httpHeaders':
        const livenessHeader = {
          name: this.livenessProbeHeadersNameFormControl.value,
          value: this.livenessProbeHeadersValueFormControl.value,
        };
        if (livenessHeader.name && livenessHeader.value) {
          this.onAddFormGroup(type, livenessHeader);
          this.livenessProbeHeadersNameFormControl.setValue('');
          this.livenessProbeHeadersValueFormControl.setValue('');
        } else {
          this.livenessProbeHeadersNameFormControl.setValue(undefined);
          this.livenessProbeHeadersValueFormControl.setValue(undefined);
        }
        break;
      case 'spec.probes.readinessProbe.httpGet.httpHeaders':
        const readinessHeader = {
          name: this.readinessProbeHeadersNameFormControl.value,
          value: this.readinessProbeHeadersValueFormControl.value,
        };
        if (readinessHeader.name && readinessHeader.value) {
          this.onAddFormGroup(type, readinessHeader);
          this.readinessProbeHeadersNameFormControl.setValue('');
          this.readinessProbeHeadersValueFormControl.setValue('');
        } else {
          this.readinessProbeHeadersNameFormControl.setValue(undefined);
          this.readinessProbeHeadersValueFormControl.setValue(undefined);
        }
        break;
      case 'spec.probes.livenessProbe.exec.command':
        if (this.livenessExecCommandFormControl.value) {
          this.onAddFormGroup(type, this.livenessExecCommandFormControl.value);
          this.livenessExecCommandFormControl.setValue('');
        } else {
          this.livenessExecCommandFormControl.setValue(undefined);
        }
        break;
      case 'spec.probes.readinessProbe.exec.command':
        if (this.readinessExecCommandFormControl.value) {
          this.onAddFormGroup(type, this.readinessExecCommandFormControl.value);
          this.readinessExecCommandFormControl.setValue('');
        } else {
          this.readinessExecCommandFormControl.setValue(undefined);
        }
        break;
      default:
        break;
    }
  }

  onRemoveElement(type: string, index: number) {
    this.onRemoveFormGroup(type, index);
  }

  onBlurCommandFormControl() {
    if (this.commandFormControl.value) {
      this.onAddElement('spec.command');
    }
  }

  onBlurArgsFormControl() {
    if (this.argsFormControl.value) {
      this.onAddElement('spec.args');
    }
  }

  onBlurLivenessExecCommandFormControl() {
    if (
      this.deploymentForm.get('spec.probes.livenessProbe.exec.command').invalid
    ) {
      this.formError = true;
    }
  }

  onBlurLivenessProbeHeadersFormControl() {
    if (
      this.livenessProbeHeadersNameFormControl.value &&
      this.livenessProbeHeadersValueFormControl.value
    ) {
      this.onAddElement('spec.probes.livenessProbe.httpGet.httpHeaders');
    }
  }

  onBlurReadinessExecCommandFormControl() {
    if (
      this.deploymentForm.get('spec.probes.readinessProbe.exec.command').invalid
    ) {
      this.formError = true;
    }
  }

  onBlurReadinessProbeHeadersFormControl() {
    if (
      this.readinessProbeHeadersNameFormControl.value &&
      this.readinessProbeHeadersValueFormControl.value
    ) {
      this.onAddElement('spec.probes.readinessProbe.httpGet.httpHeaders');
    }
  }

  invalidCustomField() {
    if (this.imageTagFormControl.invalid) {
      return true;
    }
    return false;
  }

  splitImageTag(imageTag: string) {
    if (imageTag) {
      const imageTagSplit = imageTag.split(':');
      let tag = '';
      let repository = '';

      if (imageTagSplit.length == 1) {
        repository = imageTagSplit[0];
        tag = 'latest';
      }
      if (imageTagSplit.length == 2) {
        repository = imageTagSplit[0];
        tag = imageTagSplit[1];
      }

      this.deploymentForm.patchValue({
        spec: {
          image: {
            repository: repository,
            tag: tag,
          },
        },
      });
    }
  }

  splitUnit(element: any, type) {
    const splitValue = (value: string, unit: string) => {
      if (value) {
        let unitFind = undefined;
        if (unit == 'cpu') {
          const mCore = this.cpuUnits[1];
          if (value.includes(mCore.value)) {
            unitFind = mCore;
          } else {
            unitFind = this.cpuUnits[0];
          }
        } else {
          unitFind = this.memoryUnits.find((unit) =>
            value.includes(unit.value)
          );
        }

        if (unitFind) {
          value = value.replace(unitFind.value, '');
        }

        return [value, unitFind?.value];
      }

      return [];
    };

    switch (type) {
      case 'spec.resources':
        if (element?.requests) {
          const cpuValues = splitValue(element.requests.cpu, 'cpu');
          const memoryValues = splitValue(
            element.requests.memory,
            'spec.memory'
          );
          element.requests.cpu = cpuValues[0];
          element.requests.memory = memoryValues[0];
          this.requestCpuFormControl.setValue(cpuValues[1]);
          this.requestMemoryFormControl.setValue(memoryValues[1]);
        }
        if (element?.limits) {
          const cpuValues = splitValue(element.limits.cpu, 'cpu');
          const memoryValues = splitValue(element.limits.memory, 'memory');
          element.limits.cpu = cpuValues[0];
          element.limits.memory = memoryValues[0];
          this.limitCpuFormControl.setValue(cpuValues[1]);
          this.limitMemoryFormControl.setValue(memoryValues[1]);
        }
        break;
      case 'spec.pvcs.resources.requests':
        if (element) {
          if (element.length > 0) {
            for (let index = 0; index < element.length; index++) {
              if (element[index]?.existingPvc != true) {
                const memoryValues = splitValue(
                  element[index].resources.requests.storage,
                  'memory'
                );

                element[index].resources.requests.storage = memoryValues[0];
                this.onAddFormGroup('spec.volumes.pvcs', element[index]);

                setTimeout(() => {
                  const el: any = <HTMLInputElement>(
                    document.getElementById('requestsStorage' + index)
                  );
                  if (el) {
                    el.value = memoryValues[1];
                  }
                }, 300);
              } else {
                this.onAddFormGroup('spec.volumes.pvcs', element[index]);
              }
            }
          }
        }
        break;
      default:
        break;
    }

    return element;
  }

  fillIngressesFields(ingresses: Ingress[]){
        if (ingresses) {
          if (ingresses.length == 0) {
            ingresses = [
              {
                enabled: true,
                labels: {},
                domainId: '',
                domain: '',
                domainShared: false,
                subdomain: '',
                useSocket: false,
                host: '',
                paths: [
                  {
                    path: '/',
                    pathType: 'Prefix',
                  },
                ],
              },
            ];
          }

          for (let index = 0; index < ingresses.length; index++) {
            const ingress = ingresses[index];
            this.onAddFormGroup('spec.ingress', ingress);
          }
        }
  }

  setForm() {
    if (this.deployment?.registryId) {
      this.deploymentForm.get('usePublicRegistry').setValue(false);
    } else {
      this.deploymentForm.get('usePublicRegistry').setValue(true);
    }

    if (this.deployment?.spec?.ingress?.length > 0) {
      this.appExpositionControl.setValue(true);
    }

    this.imageTagFormControl.setValue(
      `${this.deployment?.spec?.image?.repository}:${this.deployment?.spec?.image?.tag}`
    );

    this.splitUnit(this.deployment?.spec?.resources, 'spec.resources');

    const currentEnvars = this.deployment?.spec?.env;
    if (currentEnvars) {
      const keys = Object.keys(currentEnvars);
      const values = Object.values(currentEnvars);

      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        const value = values[index];
        this.onAddFormGroup('spec.env', { key: key, value: value });
      }
    }

    const emptyDirs = this.deployment?.spec?.volumes?.emptyDirs;
    if (emptyDirs) {
      for (let index = 0; index < emptyDirs.length; index++) {
        const value = emptyDirs[index];
        this.onAddFormGroup('spec.volumes.emptyDirs', value);
      }
    }

    const configMaps = this.deployment?.spec?.volumes?.configMaps;
    if (configMaps) {
      for (let index = 0; index < configMaps.length; index++) {
        const value = configMaps[index];
        this.onAddFormGroup('spec.volumes.configMaps', value);
      }
    }

    const secrets = this.deployment?.spec?.volumes?.secrets;
    if (secrets) {
      for (let index = 0; index < secrets.length; index++) {
        const value = secrets[index];
        this.onAddFormGroup('spec.volumes.secrets', value);
      }
    }

    this.splitUnit(
      this.deployment?.spec?.volumes?.pvcs,
      'spec.pvcs.resources.requests'
    );

    const envFromSecrets = this.deployment?.spec?.envFrom?.secrets;
    if (envFromSecrets) {
      for (let index = 0; index < envFromSecrets.length; index++) {
        const value = envFromSecrets[index];
        this.onAddFormGroup('spec.envFrom.secrets', value);
      }
    }

    const envFromconfigMaps = this.deployment?.spec?.envFrom?.configMaps;
    if (envFromconfigMaps) {
      for (let index = 0; index < envFromconfigMaps.length; index++) {
        const value = envFromconfigMaps[index];
        this.onAddFormGroup('spec.envFrom.configMaps', value);
      }
    }

    const autoscalingEnabled = {
      target: { checked: this.deployment?.spec?.autoscaling?.enabled },
    };
    this.onChangeEnableCheckbox(autoscalingEnabled, 'spec.autoscaling');

    const probesLivenessEnabled = {
      target: {
        checked: this.deployment?.spec?.probes?.livenessProbe?.enabled,
      },
    };
    const probesReadinessEnabled = {
      target: {
        checked: this.deployment?.spec?.probes?.readinessProbe?.enabled,
      },
    };

    if (this.deployment.spec?.probes?.livenessProbe?.enabled) {
      if (this.deployment.spec?.probes?.livenessProbe?.exec?.command) {
        this.livenessTypeFormControl.setValue('exec');
      } else if (
        this.deployment?.spec?.probes?.livenessProbe?.tcpSocket?.port
      ) {
        this.livenessTypeFormControl.setValue('tcpSocket');
      } else {
        this.livenessTypeFormControl.setValue('httpGet');
      }
    }

    if (this.deployment.spec?.probes?.readinessProbe?.enabled) {
      if (this.deployment.spec?.probes?.readinessProbe?.exec?.command) {
        this.readinessTypeFormControl.setValue('exec');
      } else if (
        this.deployment?.spec?.probes?.readinessProbe?.tcpSocket?.port
      ) {
        this.readinessTypeFormControl.setValue('tcpSocket');
      } else {
        this.readinessTypeFormControl.setValue('httpGet');
      }
    }

    this.onChangeEnableCheckbox(
      probesLivenessEnabled,
      'spec.probes.livenessProbe'
    );
    this.onChangeEnableCheckbox(
      probesReadinessEnabled,
      'spec.probes.readinessProbe'
    );

    const tolerations = this.deployment?.spec?.tolerations;
    if (tolerations) {
      for (let index = 0; index < tolerations.length; index++) {
        const value = tolerations[index];
        this.onAddFormGroup('spec.tolerations', value);
      }
    }

    const nodeSelectors = this.deployment?.spec?.nodeSelector;
    if (nodeSelectors) {
      let values = Object.values(nodeSelectors);
      let keys = Object.keys(nodeSelectors);

      for (let index = 0; index < keys.length; index++) {
        const value = {
          key: keys[index],
          value: values[index],
        };
        this.onAddFormGroup('spec.nodeSelector', value);
      }
    }

    const capabilities = this.deployment?.spec?.securityContext?.capabilities;
    if (capabilities) {
      if (capabilities.add) {
        for (let index = 0; index < capabilities.add.length; index++) {
          const element = capabilities.add[index];
          this.onAddFormGroup('spec.securityContext.capabilities.add', element);
        }
      }
      if (capabilities.drop) {
        for (let index = 0; index < capabilities.drop.length; index++) {
          const element = capabilities.drop[index];
          this.onAddFormGroup(
            'spec.securityContext.capabilities.drop',
            element
          );
        }
      }
    }

    const supplementalGroups =
      this.deployment?.spec?.podSecurityContext?.supplementalGroups;
    if (supplementalGroups) {
      for (let index = 0; index < supplementalGroups.length; index++) {
        const element = supplementalGroups[index];
        this.onAddFormGroup(
          'spec.podSecurityContext.supplementalGroups',
          element
        );
      }
    }

    const sysctls = this.deployment?.spec?.podSecurityContext?.sysctls;

    if (sysctls) {
      for (let index = 0; index < sysctls.length; index++) {
        const element = sysctls[index];
        this.onAddFormGroup('spec.podSecurityContext.sysctls', element);
      }
    }

    const command = this.deployment?.spec?.command;
    if (command) {
      for (let index = 0; index < command.length; index++) {
        const element = command[index];
        this.onAddFormGroup('spec.command', element);
      }
    }

    const args = this.deployment?.spec?.args;
    if (args) {
      for (let index = 0; index < args.length; index++) {
        const element = args[index];
        this.onAddFormGroup('spec.args', element);
      }
    }

    const livenessHttpHeaders =
      this.deployment?.spec?.probes?.livenessProbe?.httpGet?.httpHeaders;
    if (livenessHttpHeaders) {
      for (let index = 0; index < livenessHttpHeaders.length; index++) {
        const element = livenessHttpHeaders[index];
        this.onAddFormGroup(
          'spec.probes.livenessProbe.httpGet.httpHeaders',
          element
        );
      }
    }

    const readinessHttpHeaders =
      this.deployment?.spec?.probes?.readinessProbe?.httpGet?.httpHeaders;
    if (readinessHttpHeaders) {
      for (let index = 0; index < readinessHttpHeaders.length; index++) {
        const element = readinessHttpHeaders[index];
        this.onAddFormGroup(
          'spec.probes.readinessProbe.httpGet.httpHeaders',
          element
        );
      }
    }

    const livenessExecCommand =
      this.deployment?.spec?.probes?.livenessProbe?.exec?.command;
    if (livenessExecCommand) {
      for (let index = 0; index < livenessExecCommand.length; index++) {
        const element = livenessExecCommand[index];
        this.onAddFormGroup('spec.probes.livenessProbe.exec.command', element);
      }
    }

    const readinessExecCommand =
      this.deployment?.spec?.probes?.readinessProbe?.exec?.command;
    if (readinessExecCommand) {
      for (let index = 0; index < readinessExecCommand.length; index++) {
        const element = readinessExecCommand[index];
        this.onAddFormGroup('spec.probes.readinessProbe.exec.command', element);
      }
    }

    let limitGput = null;
    if (this.deployment?.spec?.resources?.limits) {
      limitGput = this.deployment?.spec?.resources?.limits['nvidia.com/gpu'];
    }

    let requestGput = null;
    if (this.deployment?.spec?.resources?.requests) {
      requestGput =
        this.deployment?.spec?.resources?.requests['nvidia.com/gpu'];
    }

    let resources = {};

    if (limitGput && requestGput) {
      this.resourcesTypeFormControl.setValue('gpu');
      resources = {
        limits: {
          gpu: limitGput,
        },
        requests: {
          gpu: requestGput,
        },
      };
    } else {
      this.resourcesTypeFormControl.setValue('cpu');
      resources = this.deployment?.spec?.resources;
    }

    this.deploymentForm.patchValue({
      registryId: this.deployment.registryId,
      spec: {
        metadata: this.deployment?.metadata,
        nameOverride: this.deployment?.spec?.nameOverride,
        replicaCount: this.deployment?.spec?.replicaCount,
        port: this.deployment?.spec?.port,
        lifecycle: this.deployment?.spec?.lifecycle,
        probes: this.deployment?.spec?.probes,
        resources: resources,
        autoscaling: this.deployment?.spec?.autoscaling,
        securityContext: this.deployment?.spec?.securityContext,
        podSecurityContext: this.deployment?.spec?.podSecurityContext,
      },
    });
  }

  invalidStep(step: number) {
    if (step == 1) {
      if (
        this.invalidCustomField() ||
        this.deploymentForm.get('spec.nameOverride').invalid ||
        this.deploymentForm.get('spec.port').invalid
      ) {
        return true;
      }
    }

    if (step == 2) {
      if (this.deploymentForm.get('spec.ingress').invalid) {
        return true;
      }
    }

    if (step == 3) {
      if (
        this.deploymentForm.get('spec.env').invalid ||
        this.deploymentForm.get('spec.envFrom').invalid
      ) {
        return true;
      }
    }
    if (step == 4) {
      if (this.deploymentForm.get('spec.autoscaling.enabled').value) {
        if (this.deploymentForm.get('spec.autoscaling').invalid) {
          return true;
        }
      }
      if (this.deploymentForm.get('spec.resources').invalid) {
        return true;
      }
    }
    if (step == 5) {
      if (this.deploymentForm.get('spec.volumes').invalid) {
        return true;
      }
    }

    if (step == 6) {
      if (this.deploymentForm.get('spec.probes').invalid) {
        return true;
      }
    }

    if (step == 10) {
      if (this.deploymentForm.get('spec.nodeSelector').invalid) {
        return true;
      }
    }

    if (step == 11) {
      if (this.deploymentForm.get('spec.tolerations').invalid) {
        return true;
      }
    }

    return false;
  }

  disableStep(step: number, index: number) {
    this.steps[index].disabled = false;

    for (let i = 0; i < this.steps.length; i++) {
      const element = this.steps[i];
      if (this.invalidStep(step) && element.value > step) {
        this.steps[i].disabled = true;
      }
    }

    return this.steps[index].disabled;
  }

  // Validator
  noDuplicateKeys(key: string): ValidatorFn {
    const keyOf = key;
    return (formArray: AbstractControl): { [key: string]: boolean } | null => {
      if (!(formArray instanceof FormArray)) {
        return null;
      }

      const keyValues = formArray.controls.map((control) =>
        keyOf
          ? (control as FormGroup).get(keyOf)?.value
          : (control as FormControl)?.value
      );

      const keyOccurrences = keyValues.reduce((acc, keyValue) => {
        acc[keyValue] = (acc[keyValue] || 0) + 1;
        return acc;
      }, {});

      formArray.controls.forEach((control, index) => {
        const keyControl = keyOf
          ? (control as FormGroup).get(keyOf)
          : (control as FormControl);
        if (keyControl) {
          if (keyOccurrences[keyControl.value] > 1) {
            keyControl.setErrors({ duplicate: true });
          } else {
            if (keyControl.hasError('duplicate')) {
              delete keyControl.errors['duplicate'];
              if (!Object.keys(keyControl.errors).length) {
                keyControl.setErrors(null);
              }
            }
          }
        }
      });

      return keyValues.some((key) =>
        keyOf ? keyOccurrences[key] > 1 : keyOccurrences > 1
      )
        ? { duplicateKeys: true }
        : null;
    };
  }

  genStr(length: number): string {
    return generateRandomString(length);
  }

  getFormArray(type: string): FormArray {
    return this.deploymentForm.get(type) as FormArray;
  }

  // REGISTRY FUNCTIONS
  getAllRegistries() {
    return new Promise((resolve, reject) => {
      this.loaderRegistry = true;
      this.appService.getRegistries().subscribe(
        (response: Registry[]) => {
          this.registries = response;
          this.tempRegistries = response;
          this.loaderRegistry = false;
          this.selectRegistry();
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  onFilterRegistry(value: string) {
    if (value) {
      this.registries = this.tempRegistries.filter((r) =>
        r.name.includes(value)
      );
    } else {
      this.registries = this.tempRegistries;
    }
  }

  onChangeRegistry(value: any) {}

  selectRegistry() {
    let registry = undefined;
    if (this.deployment?.id) {
      registry = this.registries.find(
        (r) => r.id == this.deployment?.registryId
      );
    } else {
      if (!this.deploymentForm.get('usePublicRegistry').value) {
        if (this.registries.length == 1) {
          registry = this.registries[0];
        }
      }
    }

    this.deploymentForm.get('registryId').setValue(registry?.id);
  }

  // ENVIRONMENT FUNCTIONS
  async onChangeEnvironment(value: any) {
    const project = this.projects.find(
      (p) => p.id === this.deploymentForm.get('projectId').value
    );
    if (project) {
      const environments = project.environments;
      if (environments.length > 0) {
        const environment = environments.find((e) => e.id == value);
        if (environment) {
          this.loaderDomain = true;
          await this.getDomainsByEnv(environment.id);
          this.selectDomain();
          this.loaderDomain = false;
        }
      }
    }
  }

  onFilterEnvironment(value: string) {
    const project = this.projects.find(
      (p) => p.id === this.deploymentForm.get('projectId').value
    );
    if (project) {
      const environments = project.environments;
      this.currentFilterEnvironment = '';
      if (value) {
        this.currentFilterEnvironment = value;
        this.environments = environments.filter((e) => e.name.includes(value));
      } else {
        this.environments = environments;
      }
    }
  }

  selectEnvironment() {
    let environments = [];
    let environment = undefined;
    const project = this.projects.find(
      (p) => p.id === this.deploymentForm.get('projectId').value
    );

    if (project?.id) {
      environments = project.environments;
    }

    if (this.deployment?.id) {
      environment = environments.find(
        (e) => e.id === this.deployment?.environmentId
      );
    } else {
      if (environments.length == 1) {
        environment = environments[0];
      }
    }

    this.deploymentForm.get('environmentId').setValue(environment?.id);
  }

  getEnvironmentDeployment() {
    if (this.deployment?.environmentId) {
      for (let index = 0; index < this.projects.length; index++) {
        const project = this.projects[index];
        const environment = project.environments.find(
          (e) => e.id == this.deployment?.environmentId
        );

        if (environment) {
          this.environments = project.environments;

          this.deploymentForm.get('projectId').setValue(project.id);
          this.deploymentForm.get('environmentId').setValue(environment.id);
          break;
        }
      }
    }
  }

  loadEnvironmentVariableFromFile(event: any) {
    event.forEach((m: any, index: any) => {
      let key = m[0];
      if (m[0][0] == `"` || m[0][0] == `'`) {
        key = m[0].slice(1);
        key = key.slice(0, [key.length - 1]);
      }
      let value = m[1];
      if (m[1][0] == `"` || m[1][0] == `'`) {
        value = m[1].slice(1);
        value = value.slice(0, [value.length - 1]);
      }

      this.onAddFormGroup('spec.env', { key: key, value: value });
    });
  }

  // DOMAIN FUNCTIONS
  async getDomainsByEnv(environmentId: string) {
    await this.appService
      .getDomainsByEnv(environmentId)
      .toPromise()
      .then((response: Domain[]) => {
        this.domains = response;

        this.domainsShared = true;
        response.forEach((domain) => {
          if (!domain.shared) {
            this.domainsShared = false;
          }
        });
        this.tempDomains = response;
      });
  }

  onChangeDomain(value: any, index: any) {
    const preName = `spec.ingress.${index}.`;

    const domain = this.domains.find((p) => p.id == value);
    const subdomain = this.deploymentForm.get(preName + 'subdomain').value;

    this.deploymentForm.get(preName + 'domain').setValue(domain.host);
    this.deploymentForm.get(preName + 'domainShared').setValue(domain.shared);

    if (domain.shared) {
      this.deploymentForm.get(preName + 'subdomain').disable();
    }

    this.changeHost(index, subdomain, domain.host);
  }

  selectDomain() {
    if (this.appExpositionControl.value) {
      let ingresses = this.deployment?.spec?.ingress;

      if (ingresses?.length > 0) {
        for (let index = 0; index < ingresses.length; index++) {
          const preName = `spec.ingress.${index}.`;
          const ingress: Ingress = ingresses[index];
          const domain: Domain = this.tempDomains.find(
            (d) => d.id == ingress?.domainId
          );
          if (domain) {
            this.deploymentForm.get(preName + 'domainId').setValue(domain?.id);
            this.deploymentForm.get(preName + 'domain').setValue(domain?.host);
            this.deploymentForm
              .get(preName + 'subdomain')
              .setValue(ingress.subdomain);
            this.deploymentForm
              .get(preName + 'domainShared')
              .setValue(domain.shared);

            if (this.tempDomains.length == 1 && domain?.shared == true) {
              this.deploymentForm.get(preName + 'subdomain').disable();
            }
            this.changeHost(index, ingress.subdomain, domain?.host);
          }
        }
      } else {
        if (this.tempDomains.length == 1) {
          const index = 0;
          const appName = this.deploymentForm.get('spec.nameOverride').value;
          const genStr = this.randomStr;
          const domain = this.tempDomains[index];
          const preName = `spec.ingress.${index}.`;
          let subdomain = '';

          this.deploymentForm.get(preName + 'domainId').setValue(domain?.id);
          this.deploymentForm.get(preName + 'domain').setValue(domain?.host);
          this.deploymentForm
            .get(preName + 'domainShared')
            .setValue(domain.shared);

          if (this.deploymentForm.get(preName + 'subdomain').value) {
            subdomain = this.deploymentForm.get(preName + 'subdomain').value;
          } else {
            subdomain = appName ? `${appName}-${genStr}` : genStr;
          }
          this.deploymentForm.get(preName + 'subdomain').disable();
          this.changeHost(index, subdomain, domain.host);
        }
      }
    }
  }

  onFilterDomain(value) {}

  // PROJECT FUNCTIONS
  onChangeProject(value: any) {
    const project = this.projects.find((p) => p.id == value);
    if (project?.environments) {
      this.environments = project?.environments;
    } else {
      this.environments = [];
    }

    this.selectEnvironment();
  }

  async onFilterProject(value: string) {
    this.currentFilterProject = value;
    await this.getProjects(value);
  }

  getProjects(search: string = '') {
    return new Promise((resolve, reject) => {
      this.loaderEnvironment = true;
      this.deploymentForm.get('projectId').disable();
      this.deploymentForm.get('environmentId').disable();
      this.projectService.getProjectsByOrganisation(search).subscribe(
        (response) => {
          this.projects = response.records;
          this.deploymentForm.get('projectId').enable();
          this.loaderEnvironment = false;
          this.deploymentForm.get('environmentId').enable();
          resolve(response);
        },
        (error) => {
          this.service.show({
            title: 'Deploy',
            position: Position.TOP,
            type: Type.ERROR,
            message: 'Project or Environment nor found',
          });
          this.router.navigate(['/deploy']);
          reject(error);
        }
      );
    });
  }

  selectProject() {
    let project = undefined;

    if (this.deployment?.id) {
      project = this.projects.find((p) => p.id === this.deployment?.projectId);
    } else {
      if (this.projects.length == 1) {
        project = this.projects[0];
      }
    }
    this.deploymentForm.get('projectId').setValue(project?.id);

    this.selectEnvironment();
  }

  onChangeName(event: any) {
    const genStr = this.randomStr;

    const value = event.target.value;

    let subdomain = value ? `${value}-${genStr}` : `${genStr}`;
    if (this.deployment?.id) {
      subdomain = value;
    }

    if (this.getFormArray('spec.ingress').controls.length == 1) {
      this.deploymentForm.get('spec.ingress.0.subdomain').setValue(subdomain);
    }
  }

  onChangeEnableCheckbox(event: any, type?: string) {
    const checked = event.target.checked;

    const validators1 = [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.min(1),
    ];

    const validators2 = [Validators.pattern('^[0-9]*$'), Validators.min(1)];

    switch (type) {
      case 'spec.autoscaling':
        const autoscalingControls = [
          this.deploymentForm.get('spec.autoscaling.minReplicas'),
          this.deploymentForm.get('spec.autoscaling.maxReplicas'),
          this.deploymentForm.get(
            'spec.autoscaling.targetCPUUtilizationPercentage'
          ),
          this.deploymentForm.get(
            'spec.autoscaling.targetMemoryUtilizationPercentage'
          ),
        ];

        if (checked) {
          // autoscalingControls
          for (let index = 0; index < autoscalingControls.length; index++) {
            const control: any = autoscalingControls[index];
            if (index == 3) {
              this.addValidators(control, validators2);
              continue;
            }
            this.addValidators(control, validators1);
          }

          // add validators resourceCpuControls
          if (this.resourcesTypeFormControl.value == 'cpu') {
            this.addRequiredFields('cpu', true);
          }

          // add validators resourceGpuControls
          if (this.resourcesTypeFormControl.value == 'gpu') {
            this.addRequiredFields('gpu', true);
          }
        } else {
          // remove validators autoscalingControls
          for (let index = 0; index < autoscalingControls.length; index++) {
            const control: any = autoscalingControls[index];
            if (index == 3) {
              this.removeValidators(control, validators2);
              continue;
            }
            this.removeValidators(control, validators1);
          }

          // remove validators resourceCpuControls
          if (this.resourcesTypeFormControl.value == 'cpu') {
            this.addRequiredFields('cpu', false);
          }

          // remove validators resourceGpuControls
          if (this.resourcesTypeFormControl.value == 'gpu') {
            this.addRequiredFields('gpu', false);
          }
        }
        break;
      case 'spec.probes.livenessProbe':
        const livenessControls = [
          this.deploymentForm.get(
            'spec.probes.livenessProbe.initialDelaySeconds'
          ),
          this.deploymentForm.get('spec.probes.livenessProbe.periodSeconds'),
        ];

        if (checked) {
          // probesControls
          for (let index = 0; index < livenessControls.length; index++) {
            const control: any = livenessControls[index];
            this.addValidators(control, validators1);
          }

          const livenessType = this.livenessTypeFormControl.value;

          if (['httpGet'].includes(livenessType)) {
            this.addRequiredFields('spec.probes.livenessProbe.httpGet', true);

            this.addRequiredFields('spec.probes.livenessProbe.exec', false);
            this.addRequiredFields(
              'spec.probes.livenessProbe.tcpSocket',
              false
            );

            this.clearField('spec.probes.livenessProbe.exec');
            this.clearField('spec.probes.livenessProbe.tcpSocket');
          }

          if (['exec'].includes(livenessType)) {
            this.addRequiredFields('spec.probes.livenessProbe.exec', true);

            this.addRequiredFields('spec.probes.livenessProbe.httpGet', false);
            this.addRequiredFields(
              'spec.probes.livenessProbe.tcpSocket',
              false
            );

            this.clearField('spec.probes.livenessProbe.httpGet');
            this.clearField('spec.probes.livenessProbe.tcpSocket');
          }

          if (['tcpSocket'].includes(livenessType)) {
            this.addRequiredFields('spec.probes.livenessProbe.tcpSocket', true);

            this.addRequiredFields('spec.probes.livenessProbe.httpGet', false);
            this.addRequiredFields('spec.probes.livenessProbe.exec', false);

            this.clearField('spec.probes.livenessProbe.httpGet');
            this.clearField('spec.probes.livenessProbe.exec');
          }
        } else {
          for (let index = 0; index < livenessControls.length; index++) {
            const control: any = livenessControls[index];
            this.removeValidators(control, validators1);
          }

          this.addRequiredFields('spec.probes.livenessProbe.httpGet', false);
          this.addRequiredFields('spec.probes.livenessProbe.tcpSocket', false);
          this.addRequiredFields('spec.probes.livenessProbe.exec', false);
        }
        break;
      case 'spec.probes.readinessProbe':
        const readinessControls = [
          this.deploymentForm.get(
            'spec.probes.readinessProbe.initialDelaySeconds'
          ),
          this.deploymentForm.get('spec.probes.readinessProbe.periodSeconds'),
        ];

        if (checked) {
          for (let index = 0; index < readinessControls.length; index++) {
            const control: any = readinessControls[index];
            this.addValidators(control, validators1);
          }

          const readinessType = this.readinessTypeFormControl.value;

          if (['httpGet'].includes(readinessType)) {
            this.addRequiredFields('spec.probes.readinessProbe.httpGet', true);

            this.addRequiredFields('spec.probes.readinessProbe.exec', false);
            this.addRequiredFields(
              'spec.probes.readinessProbe.tcpSocket',
              false
            );

            this.clearField('spec.probes.readinessProbe.exec');
            this.clearField('spec.probes.readinessProbe.tcpSocket');
          }

          if (['exec'].includes(readinessType)) {
            this.addRequiredFields('spec.probes.readinessProbe.exec', true);

            this.addRequiredFields('spec.probes.readinessProbe.httpGet', false);
            this.addRequiredFields(
              'spec.probes.readinessProbe.tcpSocket',
              false
            );

            this.clearField('spec.probes.readinessProbe.httpGet');
            this.clearField('spec.probes.readinessProbe.tcpSocket');
          }

          if (['tcpSocket'].includes(readinessType)) {
            this.addRequiredFields(
              'spec.probes.readinessProbe.tcpSocket',
              true
            );

            this.addRequiredFields('spec.probes.readinessProbe.httpGet', false);
            this.addRequiredFields('spec.probes.readinessProbe.exec', false);

            this.clearField('spec.probes.readinessProbe.httpGet');
            this.clearField('spec.probes.readinessProbe.exec');
          }
        } else {
          for (let index = 0; index < readinessControls.length; index++) {
            const control: any = readinessControls[index];
            this.removeValidators(control, validators1);
          }

          this.addRequiredFields('spec.probes.readinessProbe.httpGet', false);
          this.addRequiredFields('spec.probes.readinessProbe.tcpSocket', false);
          this.addRequiredFields('spec.probes.readinessProbe.exec', false);
        }
        break;
      default:
        break;
    }
  }

  onChangeExistingPvc(event, index) {
    const checked = event.target.checked;

    const controls = [
      this.deploymentForm.get(`spec.volumes.pvcs.${index}.accessModes.0`),
      this.deploymentForm.get(
        `spec.volumes.pvcs.${index}.resources.requests.storage`
      ),
    ];
    if (checked) {
      this.removeValidators(controls[0], [Validators.required]);
      this.removeValidators(controls[1], [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]);
    } else {
      this.addValidators(controls[0], [Validators.required]);
      this.addValidators(controls[1], [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]);
    }
  }

  addValidators(control: AbstractControl, validators: any) {
    control.addValidators(validators);
    control.updateValueAndValidity();
  }

  removeValidators(control: AbstractControl, validators: any) {
    control.removeValidators(validators);
    control.updateValueAndValidity();
  }

  // On add dynamic
  onAddFormGroup(type: string, data?: any) {
    let formArray = this.getFormArray(type);
    formArray.push(this.createFormGroup(type, data));
  }

  onRemoveFormGroup(type: string, index: number) {
    let formArray = this.getFormArray(type);
    formArray.removeAt(index);
  }

  private createFormGroup(type: string, data?: any): AbstractControl {
    let abstractControl: AbstractControl;
    switch (type) {
      case 'spec.ingress':
        abstractControl = this.fb.group({
          enabled: this.fb.control(true),
          labels: this.fb.group(data?.labels || {}),
          useSocket: this.fb.control(data?.useSocket || false),
          domainId: this.fb.control(data?.domainId, [Validators.required]),
          domain: this.fb.control(data?.domain, [Validators.required]),
          domainShared: this.fb.control(data?.domainShared || false),
          subdomain: this.fb.control(data?.subdomain, [
            Validators.required,
            Validators.pattern('^[^.]*$'),
          ]),
          host: this.fb.control(data?.host, [Validators.required]),
          paths: this.fb.array([
            this.fb.group({
              path: this.fb.control(data?.paths[0].path || '/', [
                Validators.required,
                Validators.pattern(this.pathK8sRegex),
              ]),
              pathType: this.fb.control('Prefix'),
            }),
          ]),
        });
        break;
      case 'spec.env':
        abstractControl = this.fb.group({
          key: this.fb.control(data?.key, [
            Validators.required,
            Validators.pattern('^[a-zA-Z][a-zA-Z0-9_]*$'),
          ]),
          value: this.fb.control(data?.value, [Validators.required]),
        });
        break;
      case 'spec.volumes.emptyDirs':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.name, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
          mountPath: this.fb.control(data?.mountPath, [
            Validators.required,
            Validators.pattern(this.pathK8sRegex),
          ]),
          enabled: this.fb.control(true),
        });
        break;
      case 'spec.volumes.configMaps':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.name, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
          mountPath: this.fb.control(data?.mountPath, [
            Validators.required,
            Validators.pattern(this.pathK8sRegex),
          ]),
          configMapRefName: this.fb.control(data?.configMapRefName, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
          enabled: this.fb.control(true),
        });
        break;
      case 'spec.volumes.secrets':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.name, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
          mountPath: this.fb.control(data?.mountPath, [
            Validators.required,
            Validators.pattern(this.pathK8sRegex),
          ]),
          secretRefName: this.fb.control(data?.secretRefName, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
          enabled: this.fb.control(true),
        });
        break;
      case 'spec.volumes.pvcs':
        abstractControl = this.fb.group({
          existingPvc: this.fb.control(data?.existingPvc ? true : false),
          name: this.fb.control(data?.name, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
          mountPath: this.fb.control(data?.mountPath, [
            Validators.required,
            Validators.pattern(this.pathK8sRegex),
          ]),
          enabled: this.fb.control(true),
          storageClass: this.fb.control(data?.storageClass),
          accessModes: this.fb.array([
            this.fb.control(
              data?.existingPvc != true ? data?.accessModes[0] : '',
              data?.existingPvc != true ? [Validators.required] : []
            ),
          ]),
          resources: this.fb.group({
            requests: this.fb.group({
              storage: this.fb.control(
                data?.existingPvc != true
                  ? data?.resources?.requests?.storage
                  : '',
                data?.existingPvc != true
                  ? [Validators.required, Validators.pattern('^[0-9]*$')]
                  : []
              ),
            }),
          }),
        });
        break;
      case 'spec.envFrom.secrets':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.name, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
        });
        break;
      case 'envFrom.configMaps':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.name, [
            Validators.required,
            Validators.pattern(this.nameK8sRegex),
          ]),
        });
        break;
      case 'spec.nodeSelector':
        abstractControl = this.fb.group({
          key: this.fb.control(data?.key, [
            Validators.required,
            Validators.pattern(this.labelK8sRegex),
          ]),
          value: this.fb.control(data?.value, [Validators.required]),
        });
        break;
      case 'spec.tolerations':
        abstractControl = this.fb.group({
          key: this.fb.control(data?.key, [
            Validators.required,
            Validators.pattern(this.labelK8sRegex),
          ]),
          operator: this.fb.control(data?.operator, [Validators.required]),
          value: this.fb.control(data?.value, [Validators.required]),
          effect: this.fb.control(data?.effect, [Validators.required]),
        });
        break;
      case 'spec.podSecurityContext.sysctls':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.key, [Validators.required]),
          value: this.fb.control(data?.value, [Validators.required]),
        });
        break;
      case 'spec.podSecurityContext.supplementalGroups':
        abstractControl = this.fb.control(data, [Validators.required]);
        break;
      case 'spec.securityContext.capabilities.add':
        abstractControl = this.fb.control(data, [Validators.required]);
        break;
      case 'spec.securityContext.capabilities.drop':
        abstractControl = this.fb.control(data, [Validators.required]);
        break;
      case 'spec.command':
        abstractControl = this.fb.control(data);
        break;
      case 'spec.args':
        abstractControl = this.fb.control(data);
        break;
      case 'spec.probes.livenessProbe.httpGet.httpHeaders':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.name),
          value: this.fb.control(data?.value),
        });
        break;
      case 'spec.probes.readinessProbe.httpGet.httpHeaders':
        abstractControl = this.fb.group({
          name: this.fb.control(data?.name),
          value: this.fb.control(data?.value),
        });
        break;
      case 'spec.probes.livenessProbe.exec.command':
        abstractControl = this.fb.control(data);
        break;
      case 'spec.probes.readinessProbe.exec.command':
        abstractControl = this.fb.control(data);
        break;
      default:
        break;
    }

    return abstractControl;
  }

  // On Change
  onChangeStorage(event: any, index: any) {
    this.storageUnits[index] = event.target.value;
  }

  onChangeResource(event: any, index: any) {
    this.resourceUnits[index] = event.target.value;
  }

  cleanSpecs(specs: any): any {
    const isEmpty = (value: any) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      } else if (value && typeof value === 'object') {
        return Object.keys(value).length === 0;
      } else if (typeof value === 'string') {
        return value.trim() === '';
      }
      return value === null || value === undefined;
    };

    const cleanValue = (value: any): any => {
      if (Array.isArray(value)) {
        return value
          .map((item) => cleanValue(item))
          .filter((item) => !isEmpty(item));
      } else if (value && typeof value === 'object') {
        return this.cleanSpecs(value);
      }
      return value;
    };

    const newSpecs = {};
    for (const key in specs) {
      if (specs.hasOwnProperty(key)) {
        const value = cleanValue(specs[key]);
        if (!isEmpty(value)) {
          newSpecs[key] = value;
        }
      }
    }

    return newSpecs;
  }

  formatData(elements: any) {
    let newData: any = {};
    if (elements.length > 0) {
      for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        newData[element.key] = element.value;
      }
    }
    return newData;
  }

  setUnit(element: any, type: string) {
    switch (type) {
      case 'spec.resources':
        if (element) {
          if (element.requests) {
            element.requests.cpu += this.requestCpuFormControl.value;
            element.requests.memory += this.requestMemoryFormControl.value;
          }
          if (element.limits) {
            element.limits.cpu += this.limitCpuFormControl.value;
            element.limits.memory += this.limitMemoryFormControl.value;
          }
        }
        break;
      case 'spec.pvcs.resources.storage':
        if (element) {
          this.storageUnits;
          if (element.length > 0) {
            for (let index = 0; index < element.length; index++) {
              if (!element[index]?.existingPvc) {
                const el: any = <HTMLInputElement>(
                  document.getElementById('requestsStorage' + index)
                );
                if (el) {
                  const value = el.value;
                  element[index].resources.requests.storage += value;
                }
              }
            }
          }
        }
        break;

      default:
        break;
    }

    return element;
  }

  parseValue(value: any, type: string): any {
    switch (type) {
      case 'string':
        return String(value);
      case 'number':
        return Number(value);
      case 'boolean':
        return Boolean(value);
      default:
        return value;
    }
  }

  deepParseObjectValues(obj: any, type: string, attributes: string[]): any {
    const parsedObject: any = Array.isArray(obj) ? [] : {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value === 'object' && value !== null) {
          parsedObject[key] = this.deepParseObjectValues(
            value,
            type,
            attributes
          );
        } else if (attributes.includes(key)) {
          parsedObject[key] = this.parseValue(value, type);
        } else {
          parsedObject[key] = value;
        }
      }
    }

    return parsedObject;
  }

  transformBooleansToEmptyString(obj: any): any {
    const transformedObject: any = Array.isArray(obj) ? [] : {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value === 'object' && value !== null) {
          transformedObject[key] = this.transformBooleansToEmptyString(value);
        } else if (typeof value === 'boolean' && !value) {
          transformedObject[key] = '';
        } else {
          transformedObject[key] = value;
        }
      }
    }

    return transformedObject;
  }

  onSubmit(): void {

    this.deploymentForm.get('spec.resources.limits.gpu').enable();

    if (this.livenessExecCommandFormControl.value) {
      this.onAddElement('spec.probes.livenessProbe.exec.command');
    }
    if (this.readinessExecCommandFormControl.value) {
      this.onAddElement('spec.probes.readinessProbe.exec.command');
    }

    this.onBlurCommandFormControl();
    this.onBlurArgsFormControl();
    this.onBlurLivenessProbeHeadersFormControl();
    this.onBlurReadinessProbeHeadersFormControl();

    this.formError = true;
    if (this.deploymentForm.invalid || this.invalidCustomField()) {
      return;
    }

    for (let index = 0; index < this.getFormArray('spec.ingress').controls.length; index++) {
      this.deploymentForm.get('spec.ingress.'+index+'.subdomain').enable();
    }

    this.deploymentForm.get('spec.nameOverride').enable();

    let submitDeployment: Deployment = this.deploymentForm.value;

    const specs: DeploymentSpecs = submitDeployment.spec;

    const project = this.projects.find((p) => submitDeployment.projectId);
    const environment = this.environments.find(
      (e) => submitDeployment.environmentId
    );

    specs.env = this.formatData(this.deploymentForm.value.spec.env) as Env;
    specs.nodeSelector = this.formatData(
      this.deploymentForm.value.spec.nodeSelector
    );

    const annotations = {
      'httpproxy.door.cloudoor.com/include-path': 'true',
    };

    if (specs?.securityContext) {
      specs.securityContext = this.transformBooleansToEmptyString(
        specs.securityContext
      );
    }

    if (specs?.podSecurityContext) {
      specs.podSecurityContext = this.transformBooleansToEmptyString(
        specs.podSecurityContext
      );
    }
    submitDeployment.metadata = {} as Metadata;
    submitDeployment.spec = this.cleanSpecs(specs);

    if (this.resourcesTypeFormControl.value == 'gpu') {
      const gpuLimit = submitDeployment.spec.resources.limits.gpu;
      const gpuRequest = submitDeployment.spec.resources.requests.gpu;

      submitDeployment.spec.resources.limits['nvidia.com/gpu'] = gpuLimit;
      submitDeployment.spec.resources.requests['nvidia.com/gpu'] = gpuRequest;

      delete submitDeployment.spec.resources.limits.gpu;
      delete submitDeployment.spec.resources.requests.gpu;
    } else {
      this.setUnit(submitDeployment.spec?.resources, 'resources');
    }

    if (submitDeployment?.spec?.probes) {
      submitDeployment.spec.probes = this.deepParseObjectValues(
        submitDeployment.spec.probes,
        'number',
        ['port', 'timeoutSeconds', 'successThreshold', 'failureThreshold']
      );
    }

    if (submitDeployment.spec?.volumes?.pvcs) {
      this.setUnit(
        submitDeployment.spec?.volumes?.pvcs,
        'pvcs.resources.storage'
      );
    }

    let ingresses = specs.ingress;
    for (let index = 0; index < ingresses.length; index++) {
      let ingress = ingresses[index];
      const labels = {};
      const domain = this.tempDomains.find((d) => d.id == ingress.domainId);

      ingresses[index].annotations = annotations;

      if (domain && ingress.enabled) {
        specs.ingress[index].secretName = domain?.certificateSecretName;
        labels['door.cloudoor.com/certificat-wildcard-name'] =
          domain?.certificateSecretName;
        labels['door.cloudoor.com/certificat-wildcard-namespace'] =
          domain?.certificateSecretNamespace;
        if (ingress.useSocket) {
          labels['door.cloudoor.com/enable-websockets'] = ingress.useSocket;
        }

        ingress.labels = labels;
      } else {
        ingress = {} as Ingress;
      }

      ingresses[index] = ingress;
    }
    submitDeployment.spec.ingress = ingresses;

    submitDeployment.organizationId = project?.organizationId;
    submitDeployment.metadata.namespace = environment?.namespace;
    submitDeployment.metadata.name = this.deploymentForm.value.spec.nameOverride;

    if (this.duplicate) {
      this.deployment = undefined;
    }

    this.formSubmitted = true;

    if (this.deployment?.id) {
      this.deploymentService
        .updateDeployment(
          submitDeployment.projectId,
          submitDeployment.environmentId,
          this.deployment.id,
          submitDeployment
        )
        .subscribe(
          (response) => {
            this.service.show({
              title: 'Deployment',
              position: Position.TOP,
              type: Type.SUCCESS,
              message: 'Updated successfully',
            });
            this.router.navigate(['/deploy']);
          },
          (error) => {
            this.formSubmitted = false;
            this.service.show({
              title: 'Deployment',
              position: Position.TOP,
              type: Type.ERROR,
              message: error.error,
            });
          }
        );
    } else {
      this.deploymentService
        .createDeployment(
          submitDeployment.projectId,
          submitDeployment.environmentId,
          submitDeployment
        )
        .subscribe(
          (response) => {
            this.service.show({
              title: 'Deployment',
              position: Position.TOP,
              type: Type.SUCCESS,
              message: 'Created successfully',
            });
            this.router.navigate(['/deploy']);
          },
          (error) => {
            this.formSubmitted = false;
            this.service.show({
              title: 'Deployment',
              position: Position.TOP,
              type: Type.ERROR,
              message: error.error,
            });
          }
        );
    }
  }
}
